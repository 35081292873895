/* @flow */
import { getAppErrorCode } from '../utils/helpres';
import moment from 'moment';

const initialAppState = {
  error: undefined,
  isLoading: true,
  isLoadingLogin: false,
  isAuthorized: false,
  authLogin: false,
  // Données pour l'utilisateur selectionné
  user: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  },
  isLoadingUser: false,
  // account connected
  account: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  },
  message: '',
  namePage: 'الرئيسية',
  activeItemSideBar: 0,
  sessions: [],
  isLoadingSessions: false,
  lawsuitsSessions: [],
  isLoadingLawsuitsSessions: false,
  lawsuits: [],
  isLoadingLawsuits: false,
  customersList: [],
  isLoadingCustomersLis: false,
  suitTypes: [],
  isLoadingSuitTypes: false,
  paramsSessions: {
    search: '',
    date: '',
    currentPage: 1,
    limit: 5,
    order: 'DESC',
  },
  paramsLawsuits: {
    search: '',
    date: '',
    currentPage: 1,
    limit: 5,
    order: 'DESC',
    customer_id: null,
  },
  paramsReport: {
    search: '',
    date: '',
    currentPage: 1,
    limit: 5,
    order: 'DESC',
  },
  paramsAppeals: {
    search: '',
    date: '',
    currentPage: 1,
    limit: 5,
    order: 'DESC',
  },
  paramsCustomer: {
    search: '',
    currentPage: 1,
    limit: 5,
  },
  customers: [],
  isLoadingCustomers: false,
  lawsuitsCustomer: {},
  isLoadingLawsuitsCustomers: false,
  customerSelected: null,
  customer: null,
  isLoadingCustomer: false,
  register: null,
  isLoadingRegister: false,
  lawyers: [],
  isLoadingLawyers: false,
  reports: [],
  isLoadingReports: false,
  updateAccount: null,
  isLoadingUpdateAccount: false,
  judgments: [],
  isLoadingJudgments: false,
  typeContracts: [],
  isLoadingTypeContract: false,
  appeals: [],
  isLoadingAppeals: false,
  allSessions: [],
  isLoadingAllSessions: false,
  allActifRDV: [],
  isLoadingAllActifRDV: false,
  countNotification: 0,
  emailExist: false,
  isLoadingGetEmail: false,
  isLoadingRestPassword: false,
  restPassword: false,
  isLoadingNotifications: false,
  notifications: [],
  isLoadingNotificationSeen: false,
  notificationSeen: 0,
  paramsNotification: {
    date: '',
    currentPage: 1,
    limit: 3,
  },
  paramsContract: {
    search: '',
    date: '',
    currentPage: 1,
    limit: 5,
    order: 'DESC',
  },
  isLoadingContracts: false,
  contracts: [],
  isLoadingBills: false,
  bills: [],
  paramsBill: {
    search: '',
    date: '',
    currentPage: 1,
    limit: 5,
    order: 'DESC',
  },
  isLoadingLastBill: false,
  lastBill: null,
  countMessageIsSeen: 0,
  paramsCalender: {
    date: new Date(),
    number_lawsuit: '',
    type: 'all',
    currentPage: 1,
    limit: 3,
  },
  isLoadingCalender: false,
  calenderData: [],
  isLoadingStatistic: false,
  statisticData: [],
  updateSecretary: false,
  isLoadingUpdateSecretary: false,
  updateLawyerTraineeShip: false,
  isLoadingUpdateLawyerTraineeShip: false,
  sandwich_menu_btn: false,
  get_img_account: null,
};

const AppReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'UPDATE_SANDWICH_MENU_BTN':
      return {
        ...state,
        sandwich_menu_btn: !state.sandwich_menu_btn,
      };
    case 'GET_IMAGE_REQUESTING':
      return {
        ...state,
        get_img_account: null,
      };
    case 'GET_IMAGE_SUCCESS':
      return {
        ...state,
        get_img_account: action.data,
      };
    case 'GET_IMAGE_FAILURE':
      return {
        ...state,
        get_img_account: null,
      };
    case 'UPDATE_LAWYER_TRAINEE_SHIP_REQUESTING':
      return {
        ...state,
        isLoadingUpdateLawyerTraineeShip: true,
        updateLawyerTraineeShip: false,
      };
    case 'UPDATE_LAWYER_TRAINEE_SHIP_SUCCESS':
      return {
        ...state,
        isLoadingUpdateLawyerTraineeShip: false,
        updateLawyerTraineeShip: true,
      };
    case 'UPDATE_LAWYER_TRAINEE_SHIP_FAILURE':
      return {
        ...state,
        isLoadingUpdateLawyerTraineeShip: false,
        updateLawyerTraineeShip: false,
      };

    case 'UPDATE_SECRETARY_REQUESTING':
      return {
        ...state,
        isLoadingUpdateSecretary: true,
      };
    case 'UPDATE_SECRETARY_SUCCESS':
      return {
        ...state,
        isLoadingUpdateSecretary: false,
        updateSecretary: true,
      };
    case 'UPDATE_SECRETARY_FAILURE':
      return {
        ...state,
        isLoadingUpdateSecretary: false,
        updateSecretary: false,
      };

    case 'GET_STATISTIC_REQUESTING': {
      return {
        ...state,
        isLoadingStatistic: true,
      };
    }
    case 'GET_STATISTIC_SUCCESS': {
      return {
        ...state,
        isLoadingStatistic: false,
        statisticData: action.data,
      };
    }
    case 'GET_STATISTIC_FAILURE': {
      return {
        ...state,
        isLoadingStatistic: false,
        statisticData: [],
      };
    }

    case 'GET_CALENDER_REQUESTING': {
      return {
        ...state,
        isLoadingCalender: true,
      };
    }
    case 'GET_CALENDER_SUCCESS': {
      return {
        ...state,
        isLoadingCalender: false,
        calenderData: action.data,
      };
    }
    case 'GET_CALENDER_FAILURE': {
      return {
        ...state,
        isLoadingCalender: false,
        calenderData: [],
      };
    }
    case 'UPDATE_COUNT_MESSAGE_SEEN':
      return {
        ...state,
        countMessageIsSeen: action.countMessageIsSeen,
      };
    case 'GET_LAST_BILL_REQUESTING': {
      return {
        ...state,
        isLoadingLastBill: true,
        lastBill: null,
      };
    }
    case 'GET_LAST_BILL_SUCCESS': {
      return {
        ...state,
        isLoadingLastBill: false,
        lastBill: action.data,
      };
    }
    case 'GET_LAST_BILL_FAILURE': {
      return {
        ...state,
        isLoadingLastBill: false,
        lastBill: null,
      };
    }
    case 'UPDATE_SEARCH_BILL_PARAMS': {
      return {
        ...state,
        paramsBill: action.paramsBill,
      };
    }
    case 'GET_BILLS_REQUESTING': {
      return {
        ...state,
        isLoadingBills: true,
      };
    }
    case 'GET_BILLS_SUCCESS': {
      return {
        ...state,
        isLoadingBills: false,
        bills: action.bills,
      };
    }
    case 'GET_BILLS_FAILURE': {
      return {
        ...state,
        isLoadingBills: false,
        bills: [],
      };
    }

    case 'UPDATE_SEARCH_CONTRACTS_PARAMS': {
      return {
        ...state,
        paramsContract: action.paramsContract,
      };
    }
    case 'GET_CONTRACTS_REQUESTING': {
      return {
        ...state,
        isLoadingContracts: true,
      };
    }
    case 'GET_CONTRACTS_SUCCESS': {
      return {
        ...state,
        isLoadingContracts: false,
        contracts: action.contracts,
      };
    }
    case 'GET_CONTRACTS_FAILURE': {
      return {
        ...state,
        isLoadingContracts: false,
        contracts: [],
      };
    }

    case 'UPDATE_SEARCH_NOTIFICATION_PARAMS': {
      return {
        ...state,
        paramsNotification: action.paramsNotification,
      };
    }
    case 'UPDATE_SEARCH_CALENDER_PARAMS': {
      return {
        ...state,
        paramsCalender: action.paramsCalender,
      };
    }
    case 'GET_NOTIFICATION_SEEN_REQUESTING': {
      return {
        ...state,
        isLoadingNotificationSeen: true,
      };
    }
    case 'GET_NOTIFICATION_SEEN_SUCCESS': {
      return {
        ...state,
        isLoadingNotificationSeen: false,
        notificationSeen: action.notifications,
      };
    }
    case 'GET_NOTIFICATION_SEEN_FAILURE': {
      return {
        ...state,
        isLoadingNotificationSeen: false,
        notificationSeen: 0,
      };
    }

    case 'GET_NOTIFICATION_REQUESTING': {
      return {
        ...state,
        isLoadingNotifications: true,
      };
    }
    case 'GET_NOTIFICATION_SUCCESS': {
      return {
        ...state,
        isLoadingNotifications: false,
        notifications: action.notifications,
      };
    }
    case 'GET_NOTIFICATION_FAILURE': {
      return {
        ...state,
        isLoadingNotifications: false,
        notifications: [],
      };
    }
    case 'RESET_PASSWORD_REQUESTING': {
      return {
        ...state,
        isLoadingRestPassword: true,
        restPassword: false,
      };
    }
    case 'RESET_PASSWORD_SUCCESS': {
      return {
        ...state,
        isLoadingRestPassword: false,
        restPassword: true,
      };
    }
    case 'RESET_PASSWORD_FAILURE': {
      return {
        ...state,
        isLoadingRestPassword: false,
        restPassword: false,
      };
    }
    case 'GET_EMAIL_USER_REQUESTING': {
      return {
        ...state,
        isLoadingGetEmail: true,
        emailExist: false,
      };
    }
    case 'GET_EMAIL_USER_SUCCESS': {
      return {
        ...state,
        isLoadingGetEmail: false,
        emailExist: action.data,
      };
    }
    case 'GET_EMAIL_USER_FAILURE': {
      return {
        ...state,
        isLoadingGetEmail: false,
        emailExist: false,
      };
    }
    case 'GET_COUNT_NOTIFICATION_REQUESTING':
      return {
        ...state,
        countNotification: 0,
      };
    case 'GET_COUNT_NOTIFICATION_SUCCESS':
      return {
        ...state,
        countNotification: action.data.data,
      };
    case 'GET_COUNT_NOTIFICATION_FAILURE':
      return {
        ...state,
        countNotification: 0,
      };
    case 'GET_RDV_ALL_ACTIF_REQUESTING':
      return {
        ...state,
        isLoadingAllActifRDV: true,
      };
    case 'GET_RDV_ALL_ACTIF_SUCCESS':
      return {
        ...state,
        isLoadingAllActifRDV: false,
        allActifRDV: action.data.data,
      };
    case 'GET_RDV_ALL_ACTIF_FAILURE':
      return {
        ...state,
        isLoadingAllActifRDV: false,
        allActifRDV: false,
      };

    case 'GET_ALL_SESSIONS_REQUESTING':
      return {
        ...state,
        isLoadingAllSessions: true,
      };
    case 'GET_ALL_SESSIONS_SUCCESS':
      return {
        ...state,
        isLoadingAllSessions: false,
        allSessions: action.allSessions.data,
      };
    case 'GET_ALL_SESSIONS_FAILURE':
      return {
        ...state,
        isLoadingAllSessions: false,
        allSessions: false,
      };
    case 'GET_APPEALS_REQUESTING':
      return {
        ...state,
        isLoadingAppeals: true,
      };
    case 'GET_APPEALS_SUCCESS':
      return {
        ...state,
        isLoadingAppeals: false,
        appeals: action.appeals,
      };
    case 'GET_APPEALS_FAILURE':
      return {
        ...state,
        isLoadingAppeals: false,
        appeals: false,
      };
    case 'GET_TYPE_CONTRACT_REQUESTING':
      return {
        ...state,
        isLoadingTypeContract: true,
      };
    case 'GET_TYPE_CONTRACT_SUCCESS':
      return {
        ...state,
        isLoadingTypeContract: false,
        typeContracts: action.typeContracts,
      };
    case 'GET_TYPE_CONTRACT_FAILURE':
      return {
        ...state,
        isLoadingTypeContract: false,
        typeContracts: false,
      };
    case 'GET_JUDGMENT_REQUESTING':
      return {
        ...state,
        isLoadingJudgments: true,
      };
    case 'GET_JUDGMENT_SUCCESS':
      return {
        ...state,
        isLoadingJudgments: false,
        judgments: action.judgments,
      };
    case 'GET_JUDGMENT_FAILURE':
      return {
        ...state,
        isLoadingJudgments: false,
        judgments: false,
      };
    case 'UPDATE_USER_REQUESTING':
      return {
        ...state,
        isLoadingUpdateAccount: true,
      };
    case 'UPDATE_USER_SUCCESS':
      return {
        ...state,
        isLoadingUpdateAccount: false,
        updateAccount: action.updateAccount,
        account: action.updateAccount,
      };
    case 'UPDATE_USER_FAILURE':
      return {
        ...state,
        isLoadingUpdateAccount: false,
        updateAccount: null,
      };
    case 'GET_USER_REQUESTING':
      return {
        ...state,
        isLoadingUser: true,
      };
    case 'GET_USER_SUCCESS':
      return {
        ...state,
        isLoadingUser: false,
        user: action.user,
      };
    case 'GET_USER_FAILURE':
      return {
        ...state,
        isLoadingUser: false,
        user: null,
      };
    case 'GET_REPORTS_REQUESTING':
      return {
        ...state,
        isLoadingReports: true,
      };
    case 'GET_REPORTS_SUCCESS':
      return {
        ...state,
        isLoadingReports: false,
        reports: action.reports,
      };
    case 'GET_REPORTS_FAILURE':
      return {
        ...state,
        isLoadingReports: false,
        reports: [],
      };

    case 'GET_LIST_LAWYER_REQUESTING':
      return {
        ...state,
        isLoadingLawyers: true,
      };
    case 'GET_LIST_LAWYER_SUCCESS':
      return {
        ...state,
        isLoadingLawyers: false,
        lawyers: action.lawyers,
      };
    case 'GET_LIST_LAWYER_FAILURE':
      return {
        ...state,
        isLoadingLawyers: false,
        lawyers: [],
      };

    case 'UPDATE_REGISTER_REQUESTING':
      return {
        ...state,
        isLoadingRegister: true,
      };
    case 'UPDATE_REGISTER_SUCCESS':
      return {
        ...state,
        isLoadingRegister: false,
        register: action.lawsuitsCustomer,
      };
    case 'UPDATE_REGISTER_FAILURE':
      return {
        ...state,
        isLoadingRegister: false,
        register: null,
      };
    case 'GET_LAWSUITS_BY_CUSTOMER_REQUESTING':
      return {
        ...state,
        isLoadingLawsuitsCustomers: true,
      };
    case 'GET_LAWSUITS_BY_CUSTOMER_SUCCESS':
      return {
        ...state,
        isLoadingLawsuitsCustomers: false,
        lawsuitsCustomer: action.lawsuitsCustomer,
      };
    case 'GET_LAWSUITS_BY_CUSTOMER_FAILURE':
      return {
        ...state,
        isLoadingLawsuitsCustomers: false,
        lawsuitsCustomer: {},
      };
    case 'GET_CUSTOMERS_REQUESTING':
      return {
        ...state,
        isLoadingCustomers: true,
      };
    case 'GET_CUSTOMERS_SUCCESS':
      return {
        ...state,
        isLoadingCustomers: false,
        customers: action.customers,
      };
    case 'GET_CUSTOMERS_FAILURE':
      return {
        ...state,
        isLoadingCustomers: false,
        customers: [],
      };
    case 'GET_CUSTOMER_REQUESTING':
      return {
        ...state,
        isLoadingCustomer: true,
      };
    case 'GET_CUSTOMER_SUCCESS':
      return {
        ...state,
        isLoadingCustomer: false,
        customer: action.customer,
      };
    case 'GET_CUSTOMER_FAILURE':
      return {
        ...state,
        isLoadingCustomer: false,
        customer: null,
      };
    case 'GET_SUIT_TYPE_REQUESTING':
      return {
        ...state,
        isLoadingSuitTypes: true,
      };
    case 'GET_SUIT_TYPE_SUCCESS':
      return {
        ...state,
        isLoadingSuitTypes: false,
        suitTypes: action.suitTypes,
      };
    case 'GET_SUIT_TYPE_FAILURE':
      return {
        ...state,
        isLoadingSuitTypes: false,
        suitTypes: [],
      };
    case 'GET_CUSTOMER_BY_LAWYER_REQUESTING':
      return {
        ...state,
        isLoadingCustomersLis: true,
      };
    case 'GET_CUSTOMER_BY_LAWYER_SUCCESS':
      return {
        ...state,
        isLoadingCustomersLis: false,
        customersList: action.customers,
      };
    case 'GET_CUSTOMER_BY_LAWYER_FAILURE':
      return {
        ...state,
        isLoadingCustomersLis: false,
        customersList: [],
      };
    case 'INITIAL_STATE_LAWSUITS': {
      return {
        ...state,
        paramsLawsuits: {
          search: '',
          date: '',
          currentPage: 1,
          limit: 5,
          order: 'DESC',
        },
      };
    }
    case 'GET_LAWSUITS_REQUESTING':
      return {
        ...state,
        isLoadingLawsuits: true,
        paramsLawsuits: action.paramsLawsuits,
      };
    case 'GET_LAWSUITS_SUCCESS':
      return {
        ...state,
        isLoadingLawsuits: false,
        lawsuits: action.lawsuits,
        paramsLawsuits: action.paramsLawsuits,
      };
    case 'GET_LAWSUITS_FAILURE':
      return {
        ...state,
        isLoadingLawsuits: false,
        lawsuits: [],
        paramsLawsuits: action.paramsLawsuits,
      };
    case 'GET_LAWSUITS_SESSION_REQUESTING':
      return {
        ...state,
        isLoadingLawsuitsSessions: true,
      };
    case 'GET_LAWSUITS_SESSION_SUCCESS':
      return {
        ...state,
        isLoadingLawsuitsSessions: false,
        lawsuitsSessions: action.lawsuitsSessions,
      };
    case 'GET_LAWSUITS_SESSION_FAILURE':
      return {
        ...state,
        isLoadingLawsuitsSessions: false,
        lawsuitsSessions: [],
      };
    case 'INITIAL_STATE_SESSION': {
      return {
        ...state,
        paramsSessions: {
          search: '',
          date: '',
          currentPage: 1,
          limit: 5,
          order: 'DESC',
        },
      };
    }
    case 'UPDATE_SEARCH_SESSIONS_PARAMS': {
      return {
        ...state,
        paramsSessions: action.paramsSessions,
      };
    }
    case 'UPDATE_SEARCH_LAWSUITS_PARAMS': {
      return {
        ...state,
        paramsLawsuits: action.paramsLawsuits,
      };
    }
    case 'UPDATE_SEARCH_CUSTOMERS_PARAMS': {
      return {
        ...state,
        paramsCustomer: action.paramsCustomer,
      };
    }
    case 'UPDATE_SEARCH_REPORTS_PARAMS': {
      return {
        ...state,
        paramsReport: action.paramsReport,
      };
    }
    case 'UPDATE_SEARCH_APPEALS_PARAMS': {
      return {
        ...state,
        paramsAppeals: action.paramsAppeals,
      };
    }
    case 'GET_SESSIONS_REQUESTING':
      return {
        ...state,
        isLoadingSessions: true,
        paramsSessions: action.paramsSessions,
      };
    case 'GET_SESSIONS_SUCCESS':
      return {
        ...state,
        isLoadingSessions: false,
        paramsSessions: action.paramsSessions,
        sessions: action.sessions,
      };
    case 'GET_SESSIONS_FAILURE':
      return {
        ...state,
        isLoadingSessions: false,
        sessions: [],
        paramsSessions: action.paramsSessions,
      };

    case 'UPDATE_ACTIVE_ITEM_SIDE_BAR':
      return {
        ...state,
        namePage: action.namePage,
        activeItemSideBar: action.activeItemSideBar,
      };

    case 'LOGIN_REQUESTING':
      return {
        ...state,
        emailExist: false,
        isLoadingLogin: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        emailExist: false,
        isLoadingLogin: false,
        authLogin: true,
        account: {
          ...action.data.lawyer,
        },
        message: action.data.message,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        emailExist: false,
        authLogin: false,
        isLoadingLogin: false,
        message: action.err,
      };

    case 'GET_ME_SUCCESS':
      return {
        ...state,
        emailExist: false,
        isAuthorized: true,
        isLoading: false,
        account: {
          ...action.data,
        },
      };
    case 'GET_ME_REQUESTING':
      return {
        ...state,
        emailExist: false,
        isAuthorized: false,
        isLoading: true,
      };
    case 'GET_ME_FAILURE':
      return {
        emailExist: false,
        isLoading: false,
        isAuthorized: false,
        error: getAppErrorCode(action.status),
        account: '',
        message: '',
      };

    case 'LOGOUT_FAILURE':
      return {
        ...state,
        isLoading: false,
        isAuthorized: false,
        account: '',
        message: '',
      };
    default:
      return state;
  }
};

export default AppReducer;
