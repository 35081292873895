import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ar from 'date-fns/locale/ar-DZ';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { courts, DaysArabic, host } from '../../../utils/constants';
import { connect } from 'react-redux';
import moment from 'moment';
import actions from '../store/actions';
import axios from 'axios';

registerLocale('ar', ar);

const mapStateToProps = (state, ownProps) => {
  const { appeal } = ownProps;
  let date_judgment;
  let date_appeal;
  let court;
  let judgment;
  let session_id;
  let session_date;
  let judgment_id;
  let judgment_name;
  let requests;
  let percent;
  let file_name;
  let id;
  let file;
  if (appeal) {
    file = appeal && appeal.file ? appeal.file : '';
    file_name = appeal && appeal.file_name ? appeal.file_name : '';
    percent = appeal ? 99 : 0;
    date_judgment = appeal && appeal.date_judgment ? appeal.date_judgment : new Date();
    date_appeal = appeal && appeal.date_appeal ? appeal.date_appeal : new Date();
    requests = appeal && appeal.requests ? appeal.requests : '';
    judgment = appeal && appeal.judgment_ ? appeal.judgment_ : '';
    id = appeal && appeal.id ? appeal.id : null;
    court = appeal && appeal.court ? appeal.court : '';
    session_id = appeal && appeal.session ? appeal.session.id : null;
    session_date =
      appeal && appeal.session
        ? moment(appeal.session.date).format('DD/MM/YYYY HH:ss')
        : '';
    judgment_id = appeal && appeal.judgment ? appeal.judgment.id : null;
    judgment_name = appeal && appeal.judgment ? appeal.judgment.name : '';
  }
  return {
    addAppeal: state.AppealReducer.addAppeal,
    isLoadingAddAppeal: state.AppealReducer.isLoadingAddAppeal,
    account: state.AppReducer.account,
    initialValues: {
      percent: percent || 0,
      file: file || null,
      id: id || '',
      judgment_name: judgment_name || '',
      file_name: file_name || '',
      session_date: session_date || '',
      judgment_id: judgment_id || null,
      session_id: session_id || null,
      judgment_: judgment || '',
      requests: requests || '',
      court: court || '',
      date_appeal: date_appeal || new Date(),
      date_judgment: date_judgment || new Date(),
    },
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitAppealForm: (values) => dispatch(actions.submitAppealForm(values)),
});

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      hasErrorDate: false,
      isOpenModal: false,
      urlImg: null,
      initialValues: {
        ...props.initialValues,
      },
    };
    this.submitForm = this.submitForm.bind(this);
    this.increase = this.increase.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }
  submitForm(initialValues) {
    if (
      !initialValues.court ||
      !initialValues.session_id ||
      !initialValues.date_judgment ||
      !initialValues.date_appeal ||
      !initialValues.judgment_id ||
      !initialValues.judgment_
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (
      initialValues.date_appeal &&
      moment().isAfter(moment(initialValues.date_appeal))
    ) {
      this.setState({
        ...initialValues,
        hasErrorDate: true,
      });
    } else if (
      initialValues.date_judgment &&
      moment().isBefore(moment(initialValues.date_judgment))
    ) {
      this.setState({
        ...initialValues,
        hasErrorDate: true,
      });
    } else {
      this.props.submitAppealForm(initialValues);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addAppeal } = this.props;
    if (addAppeal !== prevProps.addAppeal && addAppeal && addAppeal.id) {
      this.props.goBack();
    }
  }
  increase() {
    const { initialValues } = this.state;
    const newPercent = initialValues.percent + 1;
    if (newPercent >= 100) {
      clearTimeout(this.tm);
      return;
    }
    this.setState({
      initialValues: {
        ...initialValues,
        percent: newPercent,
      },
    });
    this.tm = setTimeout(this.increase, 20);
  }
  onConfirm() {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        percent: -1,
      },
    });
  }
  openModal() {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
    });
  }
  render() {
    const { isOpenModal, urlImg, initialValues, hasErrorDate, hasError } = this.state;
    const { allSessions, judgments } = this.props;

    return (
      <form action="">
        <div className="appeals_form__sections">
          <div className="appeals_form__sections-right">
            <div>
              <label htmlFor="typeOfAppeal">الجلسة</label>
              <Autocomplete
                noOptionsText={'قائمة الجلسات فارغة'}
                debug
                id="debug"
                options={allSessions}
                getOptionLabel={(option) =>
                  option.title ? moment(option.title).format('DD/MM/YYYY HH:mm') : ''
                }
                style={{ marginBottom: '4%' }}
                onChange={(event, option) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      session_id: option != null ? option.id : '',
                    },
                  });
                }}
                defaultValue={{
                  title: initialValues.session_date
                    ? moment(initialValues.session_date).format('DD/MM/YYYY HH:mm')
                    : '',
                }}
                renderInput={(params) => (
                  <TextField
                    error={!initialValues.session_id && hasError}
                    name="lawsuit_id"
                    style={{ border: 0 }}
                    {...params}
                    //label="الدعوى"
                    //variant="outlined"
                  />
                )}
              />
            </div>
            <div>
              <label htmlFor="typeOfAppeal">نوع الطعن</label>
              <Autocomplete
                debug
                id="debug"
                options={judgments}
                getOptionLabel={(option) => option.title}
                style={{ marginBottom: '4%' }}
                onChange={(event, option) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      judgment_id: option != null ? option.id : '',
                    },
                  });
                }}
                defaultValue={{
                  title: initialValues.judgment_name,
                }}
                renderInput={(params) => (
                  <TextField
                    error={!initialValues.judgment_id && hasError}
                    name="lawsuit_id"
                    style={{ border: 0 }}
                    {...params}
                    //label="الدعوى"
                    //variant="outlined"
                  />
                )}
              />
            </div>
            <div>
              <label htmlFor="court">المحكمة</label>
              <Autocomplete
                debug
                id="debug"
                options={courts}
                getOptionLabel={(option) => option.title}
                style={{ marginBottom: '4%' }}
                onChange={(event, option) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      court: option != null ? option.title : '',
                    },
                  });
                }}
                defaultValue={{
                  title: initialValues.court,
                }}
                renderInput={(params) => (
                  <TextField
                    error={!initialValues.court && hasError}
                    name="court"
                    style={{ border: 0 }}
                    {...params}
                    //label="المحكمة"
                    //variant="outlined"
                  />
                )}
              />
            </div>
            <div style={{ width: '200%' }}>
              <label htmlFor="verdict">الحكم</label>
              <textarea
                placeholder="الحكم"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      judgment_: event.target.value,
                    },
                  });
                }}
                value={initialValues.judgment_}
                className={hasError && !initialValues.judgment_ ? 'input-error' : ''}
                name="judgment_"
                id="lawsuit_events"
              >
                {initialValues.judgment_}
              </textarea>
            </div>
            <div style={{ width: '200%' }}>
              <label htmlFor="verdict">العريضة</label>
              <textarea
                placeholder="العريضة"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      requests: event.target.value,
                    },
                  });
                }}
                value={initialValues.requests}
                className={hasError && !initialValues.requests ? 'input-error' : ''}
                name="requests"
                id="lawsuit_events"
              >
                {initialValues.requests}
              </textarea>
            </div>
            <div style={{ width: '100%' }} id="upload_form">
              <input
                accept=".jpg,.png,.pdf,.jpeg"
                onChange={(e) => {
                  this.increase();
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      file: e.target.files[0],
                      name: e.target.files[0].name,
                      //percent: 0,
                    },
                  });
                }}
                name="file"
                type="file"
                id="upload_button"
                hidden
              />
              <label
                style={{ width: '40%' }}
                id="upload_button_label"
                htmlFor="upload_button"
              >
                <svg
                  height="10"
                  viewBox="0 0 448 448"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                </svg>
                اضافة ملف
              </label>
              {hasError && !initialValues.file ? (
                <span style={{ color: 'rgb(189, 35, 60) ' }}>اضافة ملف او صورة</span>
              ) : (
                ''
              )}
            </div>
            {!initialValues.file_name ? (
              ''
            ) : (
              <div style={{ display: 'block' }} className="upload_status">
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <div
                    onClick={() => {
                      axios(`${host}${initialValues.file}`, {
                        method: 'GET',
                        headers: {
                          Authorization: `JWT ${window.localStorage.getItem('token')}`,
                        },
                        responseType: 'blob', //Force to receive data in a Blob Format
                      })
                        .then((response) => {
                          let url;
                          if (initialValues.file.split('.')[1] === 'pdf') {
                            const fileP = new Blob([response.data], {
                              type: 'application/pdf',
                            });
                            url = URL.createObjectURL(fileP);

                            window.open(url, '_blank');
                          } else {
                            this.setState({
                              urlImg: URL.createObjectURL(new Blob([response.data])),
                              isOpenModal: true,
                            });
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                    style={{ cursor: 'pointer', width: '70%' }}
                    className="uploaded_file__name"
                  >
                    {initialValues.file_name}{' '}
                    {/* <a target="_blank" href={`${host}${file.file}`}>

                        </a>*/}
                  </div>
                </div>
              </div>
            )}
            {initialValues.file && !initialValues.file.name ? (
              ''
            ) : initialValues.file && initialValues.file && initialValues.file.name ? (
              <div style={{ display: 'block' }} className="upload_status">
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <div style={{ width: '30%' }} className="uploaded_file__name">
                    <a target="_blank" href={URL.createObjectURL(initialValues.file)}>
                      {initialValues.file.name}
                    </a>
                  </div>
                  <button
                    onClick={() => {
                      /* this.deleteFiles(file.id);*/
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          percent: 0,
                          file: null,
                        },
                      });
                    }}
                    type="button"
                    id="cancel_upload_button"
                  >
                    إلغاء
                  </button>
                </div>
              </div>
            ) : (
              ' '
            )}
            {/*{!initialValues.file ? (
              ''
            ) : (
              <div className="upload_status">
                <div className="uploaded_file__name">{initialValues.name}</div>
                <div id="progressBar">
                  <div
                    style={{ width: `${initialValues.percent + 1}%` }}
                    id="progressBarFill"
                  ></div>
                </div>
                <div className="upload_percenctage">{initialValues.percent + 1}%</div>
                <button
                  onClick={() =>
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        percent: 0,
                        file: null,
                        name: '',
                      },
                    })
                  }
                  type="button"
                  id="cancel_upload_button"
                >
                  إلغاء
                </button>
              </div>
            )}*/}
          </div>
          <div className="appeals_form__sections-left">
            <div className="appeals_form__sections-left_field">
              <div className="appeals_form__label" htmlFor="date_selected">
                تاريخ الطعن
              </div>
              <div style={{ display: 'block' }} className="appeals_form__cal">
                <DatePicker
                  selected={new Date(initialValues.date_appeal)}
                  locale="ar"
                  onChange={(date) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        date_appeal: date,
                      },
                    });
                  }}
                  customInput={
                    <svg
                      className="appeals_calendar_icon"
                      id="calendar_icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.38"
                      height="25.38"
                      viewBox="0 0 25.38 25.38"
                    >
                      <g id="Group_28" data-name="Group 28">
                        <g id="Group_27" data-name="Group 27">
                          <ellipse
                            id="Ellipse_20"
                            data-name="Ellipse 20"
                            cx="0.5"
                            cy="1"
                            rx="0.5"
                            ry="1"
                            transform="translate(19 9.38)"
                            fill="#a8192b"
                          />
                          <path
                            id="Path_89"
                            data-name="Path 89"
                            d="M21.414,1.983H20.125V.991a.991.991,0,1,0-1.983,0v.991H13.632V.991a.991.991,0,0,0-1.983,0v.991H7.188V.991A.991.991,0,1,0,5.2.991v.991H3.966A3.97,3.97,0,0,0,0,5.948V21.414A3.97,3.97,0,0,0,3.966,25.38H11.55a.991.991,0,0,0,0-1.983H3.966a1.985,1.985,0,0,1-1.983-1.983V5.948A1.985,1.985,0,0,1,3.966,3.966H5.2v.991a.991.991,0,0,0,1.983,0V3.966h4.461v.991a.991.991,0,0,0,1.983,0V3.966h4.511v.991a.991.991,0,0,0,1.983,0V3.966h1.289A1.985,1.985,0,0,1,23.4,5.948V11.6a.991.991,0,0,0,1.983,0V5.948A3.97,3.97,0,0,0,21.414,1.983Z"
                            fill="#a8192b"
                          />
                          <path
                            id="Path_90"
                            data-name="Path 90"
                            d="M276,270a6,6,0,1,0,6,6A6,6,0,0,0,276,270Zm0,10.013A4.015,4.015,0,1,1,280.013,276,4.02,4.02,0,0,1,276,280.013Z"
                            transform="translate(-256.616 -256.616)"
                            fill="#a8192b"
                          />
                          <path
                            id="Path_91"
                            data-name="Path 91"
                            d="M373.429,332.032h-.446v-1.041a.991.991,0,0,0-1.983,0v2.032a.991.991,0,0,0,.991.991h1.438a.991.991,0,0,0,0-1.983Z"
                            transform="translate(-352.609 -313.642)"
                            fill="#a8192b"
                          />
                          <circle
                            id="Ellipse_21"
                            data-name="Ellipse 21"
                            cx="1"
                            cy="1"
                            r="1"
                            transform="translate(14 9.38)"
                            fill="#a8192b"
                          />
                          <circle
                            id="Ellipse_22"
                            data-name="Ellipse 22"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(9 13.38)"
                            fill="#a8192b"
                          />
                          <circle
                            id="Ellipse_23"
                            data-name="Ellipse 23"
                            cx="1"
                            cy="1"
                            r="1"
                            transform="translate(5 9.38)"
                            fill="#a8192b"
                          />
                          <ellipse
                            id="Ellipse_24"
                            data-name="Ellipse 24"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(5 13.38)"
                            fill="#a8192b"
                          />
                          <circle
                            id="Ellipse_25"
                            data-name="Ellipse 25"
                            cx="1"
                            cy="1"
                            r="1"
                            transform="translate(5 18.38)"
                            fill="#a8192b"
                          />
                          <ellipse
                            id="Ellipse_26"
                            data-name="Ellipse 26"
                            cx="1.5"
                            cy="1"
                            rx="1.5"
                            ry="1"
                            transform="translate(9 18.38)"
                            fill="#a8192b"
                          />
                          <ellipse
                            id="Ellipse_27"
                            data-name="Ellipse 27"
                            cx="1.5"
                            cy="1"
                            rx="1.5"
                            ry="1"
                            transform="translate(9 9.38)"
                            fill="#a8192b"
                          />
                        </g>
                      </g>
                    </svg>
                  }
                />
              </div>
              <div className="appeals_form__date_selected">
                <div className="appeals_form__date_selected__day">
                  {' '}
                  {DaysArabic[moment(initialValues.date_appeal).format('dddd')]}
                </div>
                <div className="appeals_form__date_selected__day">
                  {moment(initialValues.date_appeal).format('DD/MM/YYYY')}
                </div>
              </div>
            </div>
            {hasErrorDate ||
            (initialValues.date_appeal &&
              moment().isAfter(moment(initialValues.date_appeal))) ? (
              <div className="rv_form__sections-left_field">
                <div>
                  <svg
                    className="rv_danger_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.077"
                    height="24.294"
                    viewBox="0 0 27.077 24.294"
                  >
                    <g id="alert" transform="translate(0 -26.315)">
                      <g
                        id="Group_39"
                        data-name="Group 39"
                        transform="translate(0 26.315)"
                      >
                        <g id="Group_38" data-name="Group 38" transform="translate(0 0)">
                          <path
                            id="Path_105"
                            data-name="Path 105"
                            d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                            transform="translate(0 -26.315)"
                            fill="#ff8000"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_41"
                        data-name="Group 41"
                        transform="translate(12.745 34.232)"
                      >
                        <g id="Group_40" data-name="Group 40" transform="translate(0 0)">
                          <rect
                            id="Rectangle_145"
                            data-name="Rectangle 145"
                            width="1.586"
                            height="7.932"
                            fill="#ff8000"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_43"
                        data-name="Group 43"
                        transform="translate(12.481 43.75)"
                      >
                        <g id="Group_42" data-name="Group 42">
                          <path
                            id="Path_106"
                            data-name="Path 106"
                            d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                            transform="translate(-236.002 -355.99)"
                            fill="#ff8000"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="rv_err_msg">الرجاء تحديد تاريخ في المستقبل</div>
              </div>
            ) : (
              ''
            )}
            <div className="appeals_form__sections-left_field">
              <div className="appeals_form__label" htmlFor="date_selected">
                تاريخ الحكم
              </div>
              <div className="appeals_form__cal">
                <DatePicker
                  selected={new Date(initialValues.date_judgment)}
                  locale="ar"
                  onChange={(date) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        date_judgment: date,
                      },
                    });
                  }}
                  customInput={
                    <svg
                      className="appeals_calendar_icon"
                      id="calendar_icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.38"
                      height="25.38"
                      viewBox="0 0 25.38 25.38"
                    >
                      <g id="Group_28" data-name="Group 28">
                        <g id="Group_27" data-name="Group 27">
                          <ellipse
                            id="Ellipse_20"
                            data-name="Ellipse 20"
                            cx="0.5"
                            cy="1"
                            rx="0.5"
                            ry="1"
                            transform="translate(19 9.38)"
                            fill="#a8192b"
                          />
                          <path
                            id="Path_89"
                            data-name="Path 89"
                            d="M21.414,1.983H20.125V.991a.991.991,0,1,0-1.983,0v.991H13.632V.991a.991.991,0,0,0-1.983,0v.991H7.188V.991A.991.991,0,1,0,5.2.991v.991H3.966A3.97,3.97,0,0,0,0,5.948V21.414A3.97,3.97,0,0,0,3.966,25.38H11.55a.991.991,0,0,0,0-1.983H3.966a1.985,1.985,0,0,1-1.983-1.983V5.948A1.985,1.985,0,0,1,3.966,3.966H5.2v.991a.991.991,0,0,0,1.983,0V3.966h4.461v.991a.991.991,0,0,0,1.983,0V3.966h4.511v.991a.991.991,0,0,0,1.983,0V3.966h1.289A1.985,1.985,0,0,1,23.4,5.948V11.6a.991.991,0,0,0,1.983,0V5.948A3.97,3.97,0,0,0,21.414,1.983Z"
                            fill="#a8192b"
                          />
                          <path
                            id="Path_90"
                            data-name="Path 90"
                            d="M276,270a6,6,0,1,0,6,6A6,6,0,0,0,276,270Zm0,10.013A4.015,4.015,0,1,1,280.013,276,4.02,4.02,0,0,1,276,280.013Z"
                            transform="translate(-256.616 -256.616)"
                            fill="#a8192b"
                          />
                          <path
                            id="Path_91"
                            data-name="Path 91"
                            d="M373.429,332.032h-.446v-1.041a.991.991,0,0,0-1.983,0v2.032a.991.991,0,0,0,.991.991h1.438a.991.991,0,0,0,0-1.983Z"
                            transform="translate(-352.609 -313.642)"
                            fill="#a8192b"
                          />
                          <circle
                            id="Ellipse_21"
                            data-name="Ellipse 21"
                            cx="1"
                            cy="1"
                            r="1"
                            transform="translate(14 9.38)"
                            fill="#a8192b"
                          />
                          <circle
                            id="Ellipse_22"
                            data-name="Ellipse 22"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(9 13.38)"
                            fill="#a8192b"
                          />
                          <circle
                            id="Ellipse_23"
                            data-name="Ellipse 23"
                            cx="1"
                            cy="1"
                            r="1"
                            transform="translate(5 9.38)"
                            fill="#a8192b"
                          />
                          <ellipse
                            id="Ellipse_24"
                            data-name="Ellipse 24"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(5 13.38)"
                            fill="#a8192b"
                          />
                          <circle
                            id="Ellipse_25"
                            data-name="Ellipse 25"
                            cx="1"
                            cy="1"
                            r="1"
                            transform="translate(5 18.38)"
                            fill="#a8192b"
                          />
                          <ellipse
                            id="Ellipse_26"
                            data-name="Ellipse 26"
                            cx="1.5"
                            cy="1"
                            rx="1.5"
                            ry="1"
                            transform="translate(9 18.38)"
                            fill="#a8192b"
                          />
                          <ellipse
                            id="Ellipse_27"
                            data-name="Ellipse 27"
                            cx="1.5"
                            cy="1"
                            rx="1.5"
                            ry="1"
                            transform="translate(9 9.38)"
                            fill="#a8192b"
                          />
                        </g>
                      </g>
                    </svg>
                  }
                />
              </div>

              <div className="appeals_form__date_selected">
                <div className="appeals_form__date_selected__day">
                  {' '}
                  {DaysArabic[moment(initialValues.date_judgment).format('dddd')]}
                </div>
                <div className="appeals_form__date_selected__day">
                  {moment(initialValues.date_judgment).format('DD/MM/YYYY')}
                </div>
              </div>
            </div>
            {hasErrorDate ||
            (initialValues.date_judgment &&
              moment().isBefore(moment(initialValues.date_judgment))) ? (
              <div className="rv_form__sections-left_field">
                <div>
                  <svg
                    className="rv_danger_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.077"
                    height="24.294"
                    viewBox="0 0 27.077 24.294"
                  >
                    <g id="alert" transform="translate(0 -26.315)">
                      <g
                        id="Group_39"
                        data-name="Group 39"
                        transform="translate(0 26.315)"
                      >
                        <g id="Group_38" data-name="Group 38" transform="translate(0 0)">
                          <path
                            id="Path_105"
                            data-name="Path 105"
                            d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                            transform="translate(0 -26.315)"
                            fill="#ff8000"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_41"
                        data-name="Group 41"
                        transform="translate(12.745 34.232)"
                      >
                        <g id="Group_40" data-name="Group 40" transform="translate(0 0)">
                          <rect
                            id="Rectangle_145"
                            data-name="Rectangle 145"
                            width="1.586"
                            height="7.932"
                            fill="#ff8000"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_43"
                        data-name="Group 43"
                        transform="translate(12.481 43.75)"
                      >
                        <g id="Group_42" data-name="Group 42">
                          <path
                            id="Path_106"
                            data-name="Path 106"
                            d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                            transform="translate(-236.002 -355.99)"
                            fill="#ff8000"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="rv_err_msg">الرجاء تحديد تاريخ في الماضي</div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="appeals_form__btn_grp">
          <button
            type="button"
            onClick={() => this.props.goBack()}
            className="appeals_cancel_session"
          >
            <svg
              className="appeals_ltSpan"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xmlnsSvgjs="http://svgjs.com/svgjs"
              version="1.1"
              width="12"
              height="12"
              x="0"
              y="0"
              viewBox="0 0 492.004 492.004"
              style={{ enableBackground: 'new 0 0 512 512' }}
              xmlSpace="preserve"
            >
              <g>
                <g xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                      fill="#2d2d2d"
                      data-original="#000000"
                      className=""
                    />
                  </g>
                </g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
              </g>
            </svg>
            الغاء
          </button>
          <button type="button" onClick={() => this.submitForm(initialValues)}>
            <svg
              className="tickSpan"
              xmlns="http://www.w3.org/2000/svg"
              width="20.125"
              height="17.774"
              viewBox="0 0 29.125 23.774"
            >
              <path
                id="Path_92"
                data-name="Path 92"
                d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                transform="translate(-1323.842 -156.357)"
                fill="none"
                stroke="#f9f9f9"
                strokeWidth="4"
              />
            </svg>
            تسجيل
          </button>
        </div>
        <div className={isOpenModal ? 'popup active' : 'popup'}>
          <div className="popup__title">{/*{appeal?.file_name}*/}</div>
          <div className="popup__item">
            <div style={{ width: '100%' }} className="popup__text">
              <div
                style={{ display: 'block', flexDirection: 'row' }}
                className="lawsuit_form__sections"
              >
                <img style={{ width: '100%', height: '500px' }} src={urlImg} />
              </div>
            </div>
          </div>
          <div className="popup__btn_grp">
            <button
              type="button"
              onClick={() => this.openModal()}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
          </div>
        </div>
        <div id="overlay" className={isOpenModal ? 'active' : ''}></div>
      </form>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);
