import React, { useState, useEffect, Fragment } from 'react';
//import React, { Component } from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';
import { NumberToLetter } from 'convertir-nombre-lettre';
import moment from 'moment';
import Icon from '../../assets/img/logo.png';
// Create styles
const newstyles = StyleSheet.create({
  page: {
    display: 'inline-grid',
    flexDirection: 'row',
    marginTop: 10,
  },
  PDFViewer: {
    width: '100%',
  },
  section: {
    marginLeft: 5,
    padding: 10,
  },
  sectionNameDoctor: {
    marginLeft: 10,
    marginRight: 50,
    padding: 10,
  },
  sectionLogo: {
    marginLeft: 10,
    marginRight: 80,
    padding: 10,
  },
  section2: {
    marginLeft: 25,
    padding: 10,
  },
  hr: {
    BorderTop: '1px bold red',
  },
  cabinet: {
    display: 'flex',
    marginTop: 80,
    flexDirection: 'row',
    marginLeft: 20,
    marginRight: 60,
  },
  sectionBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBillText: {
    color: '#070707',
    fontWeight: '1000',
    fontSize: 12,
    fontStyle: 'gras',
    //fontFamily: 'Georgia, serif',
    margin: 3,
  },
  detailBillText2: {
    color: '#070707',
    fontWeight: 'bolder',
    marginLeft: 10,
    padding: 10,
  },
  text: {
    color: '#5446db',
    fontWeight: 'bolder',
  },
  bill: {
    color: '#5446db',
    fontWeight: '500',
    marginLeft: '1%',
  },
  text1: {
    color: '#49494c',
    fontSize: 12,
    fontWeight: 'bolder',
    display: 'block',
    margin: 3,
    width: 120,
  },
  text2: {
    color: '#49494c',
    fontSize: 12,
    fontWeight: 'bolder',
    display: 'block',
    margin: 3,
    width: 150,
  },
  images: {
    marginLeft: 20,
    // padding: 10,
    width: 50,
    height: 50,
  },
  detailpaymentText: {
    //fontFamily: 'Almarai',
    color: '#070707',
    fontWeight: '1000',
    fontSize: 12,
    fontStyle: 'gras',
    //fontFamily: 'Georgia, serif',
    margin: 3,
    width: '45%',
  },
  detailpaymentText2: {
    //  fontFamily: 'Almarai',
    color: '#070707',
    fontWeight: 'bolder',
    marginLeft: 10,
    padding: 10,
  },
});
function FacturePdf(props) {
  const bill = props.location.state.bill;
  const office = props.location.state.office;
  const account = props.location.state.account;
  document.title = `Facture n°: ${bill.number}`;
  useEffect(() => {
    props.changeActiveItemSideBar(7, 'المحاسبة');
  });
  console.log(account);
  return (
    <>
      {/*<div className="bilan_details_header">
        <div
          onClick={() =>
            props.history.push({
              pathname:
                bill.regime === 'act' || bill.regime === 'cnam'
                  ? '/module-cnam'
                  : '/compta',
            })
          }
          className="back_icon"
        >
          <img src={Icon} />
        </div>
        <span>Retour</span>
      </div>*/}
      <Fragment>
        <PDFViewer style={newstyles.PDFViewer}>
          <Document>
            {/*orientation="landscape"*/}
            <Page size="A4">
              <View style={newstyles.cabinet}>
                <View style={newstyles.sectionNameDoctor}>
                  <Image style={newstyles.images} src={Icon} />
                </View>
                <View style={{ ...newstyles.section, marginTop: 5, marginLeft: 300 }}>
                  <Text style={newstyles.detailpaymentText}>Maître</Text>
                  <Text style={newstyles.detailpaymentText}>Tél </Text>
                </View>
                <View style={{ ...newstyles.section2, marginTop: 5 }}>
                  <Text style={newstyles.text1}>
                    {`: ${account.first_name} ${account.last_name}`}
                  </Text>
                  <Text style={newstyles.text1}>: {account.phone}</Text>
                </View>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                }}
              />
              <View
                style={{
                  borderBottomColor: 'black',
                  marginTop: 5,
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 50,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    textAlign: 'center',
                    //fontFamily: 'Georgia, serif',
                    margin: 3,
                  }}
                >
                  Facture n° : {bill.number}
                </Text>
              </View>
              <View
                style={{
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                  }}
                >
                  Date Facture :
                </Text>
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginRight: 30,
                    marginLeft: 10,
                  }}
                >
                  {moment(bill.created_at).format('DD/MM/YYYY')}
                </Text>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 100,
                  marginTop: 30,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginLeft: 20,
                    textDecoration: 'underline',
                  }}
                >
                  Note
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 10,
                    marginRight: 60,
                  }}
                >
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>Effectué à</Text>

                    <Text style={newstyles.text1}>Édité le</Text>
                  </View>
                  <View style={newstyles.section}>
                    <Text style={newstyles.detailBillText}>: {bill.customer.name}</Text>
                    <Text style={newstyles.detailBillText}>
                      : {moment().format('DD/MM/YYYY')}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 140,
                  marginTop: 30,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginLeft: 20,
                    textDecoration: 'underline',
                  }}
                >
                  Détails
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 10,
                    marginRight: 60,
                  }}
                >
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text2}>Total HT</Text>
                    <Text style={newstyles.text2}>TVA ( 7% )</Text>
                    <Text style={newstyles.detailBillText}>Total TTC</Text>
                  </View>
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>:</Text>
                    <Text style={newstyles.text1}>:</Text>
                    <Text style={newstyles.text1}>:</Text>
                  </View>
                  <View style={newstyles.section}>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 350,
                        textAlign: 'right',
                        //textDecoration: 'underline',
                      }}
                    >
                      {parseFloat(bill.fees).toFixed(3)}
                    </Text>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 350,
                        textAlign: 'right',
                        textDecoration: 'underline',
                      }}
                    >
                      {parseFloat((bill.fees * 7) / 100).toFixed(3)}
                    </Text>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 350,
                        textAlign: 'right',
                        // textDecoration: 'underline',
                      }}
                    >
                      {parseFloat(
                        parseFloat((bill.fees * 7) / 100) + parseFloat(bill.fees),
                      ).toFixed(3)}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 50,
                  marginRight: 60,
                  marginTop: 3,
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                  }}
                >
                  Arretée la présente facture à la somme de :
                </Text>
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                  }}
                >
                  #{' '}
                  {NumberToLetter(
                    parseFloat(parseFloat((bill.fees * 7) / 100) + parseFloat(bill.fees))
                      .toFixed(3)
                      .toString()
                      .replace('.', ''),
                  )
                    .replace('mille', 'Dinars')
                    .replace('millions', 'mille')}{' '}
                  #
                </Text>
              </View>
              <View
                style={{
                  height: 50,
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '100%',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                <View
                  style={{
                    borderBottomColor: 'black',
                    marginLeft: 50,
                    marginRight: 60,
                    //marginTop: 350,
                    borderBottomWidth: 1,
                  }}
                />
                <View
                  style={{
                    display: 'block',
                  }}
                >
                  <Text
                    style={{
                      top: 10,
                      color: '#070707',
                      fontWeight: '1000',
                      fontSize: 10,
                      fontStyle: 'gras',
                      //fontFamily: 'Georgia, serif',
                      margin: 3,
                    }}
                  >
                    {account.address_street
                      ? `${account.address_street} ${account.address_city} ${account.zip_code}`
                      : '-'}{' '}
                  </Text>
                  <Text
                    style={{
                      top: 10,
                      color: '#070707',
                      fontWeight: '1000',
                      fontSize: 10,
                      fontStyle: 'gras',
                      //fontFamily: 'Georgia, serif',
                      margin: 3,
                    }}
                  >
                    {account && account.fix_number
                      ? `Tél/Fax: +216 ${account.fix_number} `
                      : '-'}
                    GSM: +216 {account.phone}
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </Fragment>
    </>
  );
}

export default FacturePdf;
