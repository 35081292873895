import React, { Component } from 'react';
import actions from '../../../js/actions';
import actionsCustomer from '../../customer/store/actions';
import { connect } from 'react-redux';
import SearchIcon from '../../../assets/img/search-interface-symbol_sm.svg';
import { Link } from 'react-router-dom';
import Item from '../../customer/components/item';
import { Skeleton } from '@material-ui/lab';
import EmptyData from '../../../assets/img/undraw_design_team_af2y.svg';
import moment from 'moment';
import { isHasPermission } from '../../../utils/helpres';
import Tooltip from '@mui/material/Tooltip';

const mapStateToProps = (state) => ({
  customerSelected: state.AppReducer.customerSelected,
  customers: state.AppReducer.customers,
  isLoadingCustomers: state.AppReducer.isLoadingCustomers,
  customer: state.AppReducer.customer,
  isLoadingCustomer: state.AppReducer.isLoadingCustomer,
  paramsCustomer: state.AppReducer.paramsCustomer,
  isLoadingLawsuitsCustomers: state.AppReducer.isLoadingLawsuitsCustomers,
  lawsuitsCustomer: state.AppReducer.lawsuitsCustomer,
  account: state.AppReducer.account,
});

const mapDispatchToProps = (dispatch) => ({
  getListCustomer: (paramsCustomer) =>
    dispatch(actions.getListCustomer(paramsCustomer, 1)),
  getCustomerById: (id) => dispatch(actionsCustomer.getCustomerById(id)),
  getListLawsuitsByIdCustomer: (customer_id) =>
    dispatch(actions.getListLawsuitsByIdCustomer(customer_id)),
  changeSearchedStringCustomers: async (paramsCustomer) => {
    await dispatch(actions.changeSearchedStringCustomers(paramsCustomer));
    dispatch(actions.getListCustomer(paramsCustomer, 1));
  },
});

class CustomerArchive extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBar(6, 'ملفات الحرفاء');
    document.title = 'ملفات الحرفاء';
    this.state = {
      customer_selected: null,
      customer_selected_code: null,
    };
    this.handleChangedCustomerSelected = this.handleChangedCustomerSelected.bind(this);
  }
  componentDidMount() {
    this.props.changeSearchedStringCustomers({
      search: '',
      currentPage: 1,
      limit: 5,
    });
  }
  handleChangedCustomerSelected(id, code) {
    this.setState({
      customer_selected: id,
      customer_selected_code: code,
    });
    this.props.getCustomerById(id);
    this.props.getListLawsuitsByIdCustomer(code);
  }
  render() {
    const {
      customer,
      isLoadingCustomer,
      isLoadingLawsuitsCustomers,
      customers,
      account,
      paramsCustomer,
      lawsuitsCustomer,
    } = this.props;

    console.log(lawsuitsCustomer);
    const { customer_selected, customer_selected_code } = this.state;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-ملفات-الحرفاء',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-ملفات-الحرفاء',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-ملفات-الحرفاء',
    );
    let isPermissionAddLawsuit = isHasPermission(
      account.login_access.permissions,
      'إضافة-الدعاوى',
    );
    return (
      <div className="clients_middle">
        <div className="clients_middle__wrapper">
          <>
            <div className="clients_col-right">
              <div className="clients_right_search">
                <img src={SearchIcon} alt="search icon" />
                <input
                  onChange={(e) =>
                    this.props.changeSearchedStringCustomers({
                      ...paramsCustomer,
                      search: e.target.value,
                    })
                  }
                  value={paramsCustomer && paramsCustomer.search}
                  type="text"
                />
              </div>
              {customers && customers.data && customers.data.length > 0
                ? customers.data.map((customer) => {
                    return (
                      <Item
                        handleChangedCustomerSelected={this.handleChangedCustomerSelected}
                        getCustomerById={this.props.getCustomerById}
                        getListLawsuitsByIdCustomer={
                          this.props.getListLawsuitsByIdCustomer
                        }
                        customer_selected={customer_selected || customers.data[0].id}
                        customer_selected_code={
                          customer_selected_code || customers.data[0].code
                        }
                        customer={customer}
                        key={customer.id}
                      />
                    );
                  })
                : ''}
            </div>
            {customers && customers.data && customers.data.length > 0 ? (
              <div className="clients_col-left">
                <div className="clients_details_section">
                  <div
                    style={{
                      height: '3rem',
                      fontWeight: 'bold',
                    }}
                    className="clients_details_section__head"
                  >
                    بيانات الحريف
                    {/*{customer && customer.id && isPermissionDelete ? (
                      <Tooltip title="حذف">
                        <div className="jalaset_table__del">
                          <svg
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.setState({
                                customer_selected: customer.id,
                                openDeletePopup: true,
                              });
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.906"
                            height="25.74"
                            viewBox="0 0 20.906 25.74"
                          >
                            <g id="delete" transform="translate(0 0)">
                              <path
                                id="Path_82"
                                data-name="Path 82"
                                d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                                transform="translate(-208.991 -145.377)"
                                fill="#ca2946"
                              />
                              <path
                                id="Path_83"
                                data-name="Path 83"
                                d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                                transform="translate(-98.105 -145.377)"
                                fill="#ca2946"
                              />
                              <path
                                id="Path_84"
                                data-name="Path 84"
                                d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                                transform="translate(0.003 0.002)"
                                fill="#ca2946"
                              />
                              <path
                                id="Path_85"
                                data-name="Path 85"
                                d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                                transform="translate(-153.548 -145.377)"
                                fill="#ca2946"
                              />
                            </g>
                          </svg>
                        </div>
                      </Tooltip>
                    ) : (
                      ''
                    )}*/}
                  </div>
                  {customer && customer.id ? (
                    isLoadingCustomer ? (
                      <div className="clients_details_section__body">
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">
                            <Skeleton
                              style={{
                                padding: '10px',
                              }}
                              variant="rect"
                              width={50}
                              height={20}
                            />
                          </div>
                          <div className="clients_data_name">
                            <Skeleton
                              style={{
                                padding: '10px',
                              }}
                              variant="rect"
                              width={150}
                              height={20}
                            />
                          </div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">
                            <Skeleton
                              style={{
                                padding: '10px',
                              }}
                              variant="rect"
                              width={50}
                              height={20}
                            />
                          </div>
                          <div className="clients_data_name">
                            <Skeleton
                              style={{
                                padding: '10px',
                              }}
                              variant="rect"
                              width={150}
                              height={20}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="clients_details_section__body">
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">كود</div>
                          <div className="clients_data_name">{customer.code}</div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">الإسم</div>
                          <div className="clients_data_name">{customer.name}</div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">الهاتف</div>
                          <div className="clients_data_phone">{customer.phone}</div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">ب ت</div>
                          <div className="clients_data_addr">{customer.cin}</div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">المهنة</div>
                          <div className="clients_data_addr">{customer.post}</div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">الجنسية</div>
                          <div className="clients_data_addr">{customer.nationality}</div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">المدينة</div>
                          <div className="clients_data_city">{customer.address_city}</div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">العنوان</div>
                          <div className="clients_data_addr">{` ${customer.address_street} - ${customer.address_city} `}</div>
                        </div>
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="clients_col-right"></div>
                <div
                  style={{ gap: '10%', marginTop: '8%', display: 'grid' }}
                  className="clients_col-left"
                >
                  <img style={{ textAlign: 'center' }} src={EmptyData} />
                  <span style={{ marginRight: '12%', fontSize: '15px' }}>
                    لا يوجد سجل متاح
                  </span>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerArchive);
