import React, { Component } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import actions from '../store/actions';
import { connect } from 'react-redux';
import { host } from '../../../utils/constants';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import axios from 'axios';
import InputField from '../../../components/inputField';

const mapStateToProps = (state) => ({
  lawsuit: state.LawsuitReducer.lawsuit,
  isLoadingLawsuit: state.LawsuitReducer.isLoadingLawsuit,
  addComment: state.LawsuitReducer.addComment,
});

const mapDispatchToProps = (dispatch) => ({
  getLawsuitById: (id) => dispatch(actions.getLawsuitById(id)),
  submitCommentLawsuit: async (values, id) => {
    await dispatch(actions.submitCommentLawsuit(values));
    dispatch(actions.getLawsuitById(id));
  },
});

class Show extends Component {
  constructor(props) {
    super(props);
    props.getLawsuitById(this.props.selected_id);
    this.state = {
      isOpenModal: false,
      isOpenModalComment: false,
      urlImg: null,
      hasError: false,
      comment: '',
    };
  }
  openModal() {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addComment } = this.props;

    if (addComment !== prevProps.addComment && addComment) {
      this.setState({
        isOpenModalComment: false,
      });
    }
  }

  render() {
    const { left, anchor, lawsuit, isLoadingLawsuit } = this.props;
    const { comment, hasError, isOpenModalComment, isOpenModal, urlImg } = this.state;
    return (
      <SwipeableDrawer
        style={{ width: '40rem' }}
        anchor="left"
        open={left}
        onClose={this.props.toggleDrawer(anchor, false)}
        onOpen={this.props.toggleDrawer(anchor, true)}
      >
        {isLoadingLawsuit ? (
          <div
            style={{
              position: 'initial',
              width: '70rem',
              display: 'flex',
            }}
            className="rv_newDate"
          >
            <div className="clients_details_section">
              <CircularProgress
                style={{
                  textAlign: 'center',
                  width: '30px',
                  height: '30px',
                  marginTop: '39%',
                  marginRight: '42%',
                }}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              position: 'initial',
              width: '75rem',
              display: 'flex',
            }}
            className="rv_newDate"
          >
            <div className="clients_details_section">
              <div className="clients_details_section__body">
                <div ref={(el) => (this.componentRef = el)}>
                  <div
                    style={{ justifyContent: 'center' }}
                    className="clients_details_section__field"
                  >
                    <div className="clients_field_title item-name">دعوى عدد </div>
                    <div className="clients_data_name">{lawsuit.number}</div>
                  </div>
                  {lawsuit.responsible ? (
                    <div className="clients_details_section__field">
                      <div className="clients_field_title item-name">يسند إلى</div>
                      <div className="clients_data_name">{`${lawsuit.responsible.first_name} ${lawsuit.responsible.last_name}`}</div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="clients_details_section__field">
                    <div className="clients_field_title item-name">المنوب</div>
                    <div className="clients_data_name">{lawsuit.customer.name}</div>
                  </div>
                  {lawsuit.defendants &&
                    lawsuit.defendants.length > 0 &&
                    lawsuit.defendants.map((defendant) => {
                      return (
                        <div
                          key={defendant.id}
                          className="clients_details_section__field"
                        >
                          <div className="clients_field_title item-name">الضد</div>
                          <div className="clients_data_phone">
                            {defendant.display_name}
                          </div>
                          <div className="clients_data_phone">{defendant.cin}</div>
                          <div className="clients_data_phone">{defendant.post}</div>
                          <div className="clients_data_phone">
                            {defendant.address_street}
                          </div>
                        </div>
                      );
                    })}
                  <div className="clients_details_section__field">
                    <div className="clients_field_title item-name">نوع الدعوى</div>
                    <div className="clients_data_addr">{lawsuit.suit_type.name}</div>
                  </div>
                  <div className="clients_details_section__field">
                    <div className="clients_field_title item-name">المحكمة</div>
                    <div className="clients_data_addr">{lawsuit.court}</div>
                  </div>
                  <div
                    style={{ display: 'block' }}
                    className="clients_details_section__field"
                  >
                    <div className="clients_field_title item-name">مآل القضية</div>
                    {lawsuit.sessions &&
                      lawsuit.sessions.length > 0 &&
                      lawsuit.sessions.map((session) => {
                        return (
                          <>
                            <div
                              key={session.id}
                              className="clients_fdetails_section__field"
                            >
                              <div className="clients_data_addr">
                                {' '}
                                {moment(session.date).format('dddd')}
                                {'  '}
                                {moment(session.date).format('YYYY/MM/DD')}
                                {'  '}
                                <span style={{ color: '#ca2946' }}>
                                  {moment(session.date).format('HH:mm')}h
                                </span>
                              </div>
                            </div>
                            {/*
                            {lawsuit.sessions.length > 4 ? <br /> : ''}*/}
                          </>
                        );
                      })}
                  </div>
                  <span className="ls_seperator">العريضة</span>
                  <div className="clients_details_section__field">
                    <div className="clients_field_title item-name">وقائع الدعوى</div>
                    <div className="clients_data_addr">{lawsuit.facts}</div>
                  </div>
                  <div className="clients_details_section__field">
                    <div className="clients_field_title item-name">الادلة</div>
                    <div className="clients_data_addr">{lawsuit.evidence}</div>
                  </div>
                  <div className="clients_details_section__field">
                    <div className="clients_field_title item-name">الطلبات</div>
                    {lawsuit.requests}
                    {/* {lawsuit.requests ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: draftToHtml(JSON.parse(lawsuit.requests)),
                        }}
                        className="clients_data_addr"
                      ></div>
                    ) : (
                      ''
                    )}*/}
                  </div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">الملفات</div>
                  <div className="clients_data_addr">
                    {lawsuit.lawsuit_files && lawsuit.lawsuit_files.length > 0
                      ? lawsuit.lawsuit_files.map((file) => {
                          return (
                            <div
                              key={file.id}
                              style={{ display: 'flex', marginBottom: '10px' }}
                            >
                              <div
                                onClick={() => {
                                  console.log(`${host}${file.file}`);
                                  axios(`${host}${file.file}`, {
                                    method: 'GET',
                                    headers: {
                                      Authorization: `JWT ${window.localStorage.getItem(
                                        'token',
                                      )}`,
                                    },
                                    responseType: 'blob', //Force to receive data in a Blob Format
                                  })
                                    .then((response) => {
                                      //Create a Blob from the PDF Stream
                                      let url;
                                      if (file.file_name.split('.')[1] === 'pdf') {
                                        const fileP = new Blob([response.data], {
                                          type: 'application/pdf',
                                        });
                                        url = URL.createObjectURL(fileP);
                                        window.open(url, '_blank');
                                      } else if (file.file.split('.')[1] === 'docx') {
                                        var blob = new Blob([response.data], {
                                          type: 'application/octet-stream',
                                        });

                                        var link = document.createElement('a');
                                        link.href = URL.createObjectURL(blob);
                                        // set the name of the file
                                        link.download = `${lawsuit.number}.docx`;
                                        // clicking the anchor element will download the file
                                        link.click();
                                      } else {
                                        this.setState({
                                          urlImg: URL.createObjectURL(
                                            new Blob([response.data]),
                                          ),
                                          isOpenModal: true,
                                        });
                                      }
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });
                                }}
                                style={{
                                  cursor: 'pointer',
                                  width: '100%',
                                  color: 'blue',
                                }}
                                className="uploaded_file__name"
                              >
                                {file.file_name}
                              </div>
                            </div>
                          );
                        })
                      : 'قوائم المستندات فارغة'}
                  </div>
                </div>
                <span className="ls_seperator">الأتعاب</span>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">الأتعاب</div>
                  <div className="clients_data_addr">{lawsuit.fees}</div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">تسبقة</div>
                  <div className="clients_data_addr">{lawsuit.advance_fees}</div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">باقي الأتعاب</div>
                  <div className="clients_data_addr">{lawsuit.remaining_fees}</div>
                </div>
                <span className="ls_seperator">التعليقات</span>
                {lawsuit.lawsuit_comments && lawsuit.lawsuit_comments.length > 0 ? (
                  <>
                    {lawsuit.lawsuit_comments.map((comment) => {
                      return (
                        <div key={comment.id} className="clients_fdetails_section__field">
                          <div className="clients_details_section__field">
                            {comment.type === 'lawyer' ? (
                              <div className="clients_field_title item-name">الاستاذ</div>
                            ) : (
                              <div className="clients_field_title item-name">المنوب</div>
                            )}
                            <div className="clients_data_addr">{comment.comment}</div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ''
                )}
                <div
                  style={{ justifyContent: 'start', marginTop: '1%' }}
                  className="lawsuit_form__btn_grp"
                >
                  <button
                    onClick={() => this.setState({ isOpenModalComment: true })}
                    type="button"
                  >
                    اضافة تعليق
                  </button>
                </div>
                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <button
                        style={{ color: '#000000', fontSize: '15px' }}
                        type="button"
                        className="btn btn-imprimer-seance"
                        onClick={handlePrint}
                      >
                        طباعة
                      </button>
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
            </div>
          </div>
        )}
        <div className={isOpenModal ? 'popup active' : 'popup'}>
          <div className="popup__title">{/*{appeal?.file_name}*/}</div>
          <div className="popup__item">
            <div style={{ width: '100%' }} className="popup__text">
              <div
                style={{ display: 'block', flexDirection: 'row' }}
                className="lawsuit_form__sections"
              >
                <img style={{ width: '100%', height: '500px' }} src={urlImg} />
              </div>
            </div>
          </div>
          <div className="popup__btn_grp">
            <button
              type="button"
              onClick={() => this.openModal()}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
          </div>
        </div>
        <div
          id="overlay"
          className={isOpenModal || isOpenModalComment ? 'active' : ''}
        ></div>
        <div className={isOpenModalComment ? 'popup active' : 'popup'}>
          <div className="popup__title">اضافة تعليق</div>
          <div className="popup__item">
            <div className="popup__text">
              <div style={{ flexDirection: 'row' }} className="lawsuit_form__sections">
                <label htmlFor="attr">اضافة تعليق</label>
                <InputField
                  //onKeyPress={() => this.submitForm(initialValues)}
                  value={comment}
                  name="comment"
                  placeholder="تعليق"
                  type="text"
                  hasError={hasError}
                  validInput="text"
                  onChange={(event) => {
                    this.setState({
                      comment: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="popup__btn_grp">
            <button
              onClick={() => this.setState({ isOpenModalComment: false })}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              style={{
                background: '#63B17D',
              }}
              onClick={() => {
                if (comment === '') {
                  this.setState({
                    hasError: true,
                  });
                } else {
                  this.setState({
                    hasError: false,
                  });
                  this.props.submitCommentLawsuit(
                    {
                      lawsuit_id: lawsuit.id,
                      type: 'lawyer',
                      comment,
                    },
                    lawsuit.id,
                  );
                }
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  className="tickSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.125"
                  height="17.774"
                  viewBox="0 0 29.125 23.774"
                >
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                    transform="translate(-1323.842 -156.357)"
                    fill="none"
                    stroke="#f9f9f9"
                    strokeWidth="4"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Show);
