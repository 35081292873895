import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { classTask, host } from '../../../utils/constants';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import High from '../../../assets/task/high.svg';
import Medium from '../../../assets/task/medium.svg';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import io from 'socket.io-client';
import { isHasPermission } from '../../../utils/helpres';
const { uuid } = require('uuidv4');

function ListTasks(props) {
  const { secretaries, account } = props;
  let accountId = account.id;
  if (account.login_access.account_type === 'secretary') accountId = account.lawyer_id;
  if (account.login_access.account_type === 'lawyer' && account.pack === 'traineeship')
    accountId = account.user_id;
  const [openCreatePostPopup, setOpenCreatePostPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [task, setTask] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [openOption, setOpenOption] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const today = moment();
  var now = moment();
  var monday = now.clone().weekday(1);
  var friday = now.clone().weekday(6);
  const [date_from, setDateFrom] = useState(monday);
  const [date_to, setDateTo] = useState(friday);

  const [columnsFromBackend, setColumnsFromBackend] = useState({
    [uuid()]: {
      name: 'ما يجب انجازه',
      items: [],
      type: 'to_do',
    },
    [uuid()]: {
      name: 'في تقدم',
      type: 'in_progress',
      items: [],
    },
    [uuid()]: {
      name: 'منجز',
      type: 'done',
      items: [],
    },
  });
  const [initialValues, setInitialValues] = useState({
    accountId,
    lawyer_id: accountId,
    date_from: moment(date_from).format('YYYY-MM-DD'),
    date_to: moment(date_to).format('YYYY-MM-DD'),
    reporter_id: null,
    title: '',
    description: '',
    importance: null,
    class: null,
  });
  const socket = useRef(null);

  useEffect(
    () => {
      socket.current = io.connect(host);
      // socket.current.emit(`connect-user-task`, { account, params }, (error) => {
      //   if (error) {
      //     setFlag(1);
      //     alert(error);
      //   }
      // });
      socket.current.emit(`join-task`, { accountId }, (error) => {
        if (error) {
          setFlag(1);
          alert(error);
        }
      });
      socket.current.emit(`tasks`, { accountId, date_from, date_to }, (error) => {
        if (error) {
          alert(error);
        }
      });
      socket.current.on('get-tasks', (result, error) => {
        setIsLoading(false);
        if (result) {
          setColumnsFromBackend({
            [uuid()]: {
              name: 'ما يجب انجازه',
              type: 'to_do',
              items: result.to_do,
            },
            [uuid()]: {
              name: 'في تقدم',
              type: 'in_progress',
              items: result.in_progress,
            },
            [uuid()]: {
              name: 'منجز',
              type: 'done',
              items: result.done,
            },
          });
          setColumns({
            [uuid()]: {
              name: 'ما يجب انجازه',
              type: 'to_do',
              items: result.to_do,
            },
            [uuid()]: {
              name: 'في تقدم',
              type: 'in_progress',
              items: result.in_progress,
            },
            [uuid()]: {
              name: 'منجز',
              type: 'done',
              items: result.done,
            },
          });
          setTasks(result);
        } else setTasks([]);
        if (error) {
          alert(error);
        }
      });
    },
    [],
    tasks,
  );
  const [columns, setColumns] = useState(columnsFromBackend);
  const addTask = (event) => {
    event.preventDefault();
    if (
      !initialValues.reporter_id ||
      !initialValues.class ||
      // !initialValues.title ||
      !initialValues.description ||
      !initialValues.importance
    ) {
      setHasError(true);
    } else {
      setInitialValues({
        ...initialValues,
        accountId,
      });
      initialValues.lawyer_id = accountId;
      initialValues.accountId = accountId;
      socket.current.emit('add-task', { ...initialValues, date_from, date_to }, () =>
        setInitialValues({
          id: null,
          reporter_id: null,
          title: '',
          description: '',
          importance: null,
          class: null,
        }),
      );
      socket.current.emit(`tasks`, { accountId, date_from, date_to }, (error) => {
        if (error) {
          alert(error);
        }
      });
      setOpenCreatePostPopup(false);
    }
  };
  const updateTask = (event) => {
    event.preventDefault();
    if (
      !initialValues.id ||
      !initialValues.reporter_id ||
      !initialValues.class ||
      //!initialValues.title ||
      !initialValues.description ||
      !initialValues.type ||
      !initialValues.importance
    ) {
      setHasError(true);
    } else {
      socket.current.emit(
        'update-task',
        { initialValues, date_from, date_to, accountId },
        () =>
          setInitialValues({
            reporter_id: null,
            title: '',
            description: '',
            importance: null,
            class: null,
          }),
      );
      setOpenCreatePostPopup(false);
    }
  };
  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      setInitialValues('');
      socket.current.emit(
        'change-task',
        {
          tasks: destItems,
          type: columns[destination.droppableId].type,
          date_from,
          date_to,
          accountId,
        },
        () =>
          setInitialValues({
            ...initialValues,
            id: destItems.id,
            type: columns[destination.droppableId].type,
          }),
      );
      socket.current.emit(`tasks`, { accountId, date_from, date_to }, (error) => {
        if (error) {
          alert(error);
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };
  const deleteTask = () => {
    socket.current.emit(
      `delete-task`,
      { id: initialValues.id, date_from, date_to, accountId },
      (error) => {
        if (error) {
          alert(error);
        }
      },
    );
    setOpenDeletePopup(false);
  };
  return (
    <div className="lj_middle">
      <div className="table_controls">
        {isHasPermission(account.login_access.permissions, 'إضافة-المهام') ? (
          <a
            onClick={() => {
              setOpenCreatePostPopup(true);
              setInitialValues({
                // ...initialValues,
                id: null,
                reporter_id: '',
                title: '',
                description: '',
                importance: '',
                class: '',
              });
            }}
            className="jalaset_add_button"
          >
            <svg
              height="10"
              viewBox="0 0 448 448"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0"></path>
            </svg>
            اضافة مهمة
          </a>
        ) : (
          ''
        )}

        <div className="sort_grp" style={{ width: '50%' }}>
          <div className="sort_grp__txt">ترتيب حسب</div>
          <div style={{ width: '80%' }} className="sort_grp__buttons">
            <div style={{ marginLeft: '5%', display: 'flex' }}>
              <span style={{ fontSize: '13px' }}>من</span>
              <DatePicker
                selected={new Date()}
                locale="ar"
                onChange={async (date) => {
                  await setDateFrom(moment(date).format('YYYY-MM-DD'));
                  socket.current.emit(
                    `tasks`,
                    {
                      accountId,
                      date_from: moment(date).format('YYYY-MM-DD'),
                      date_to: moment(date_to).format('YYYY-MM-DD'),
                    },
                    (error) => {
                      if (error) {
                        alert(error);
                      }
                    },
                  );
                  /*await onChangeDate(
                    moment(date).format('YYYY-MM-DD'),
                    moment(date_to).format('YYYY-MM-DD'),
                  );*/
                }}
                customInput={
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g id="Group_28" data-name="Group 28">
                      <g id="Group_27" data-name="Group 27">
                        <ellipse
                          id="Ellipse_20"
                          data-name="Ellipse 20"
                          cx="1"
                          cy="1.5"
                          rx="1"
                          ry="1.5"
                          transform="translate(22 11)"
                          fill="#ca2946"
                        />
                        <path
                          id="Path_89"
                          data-name="Path 89"
                          d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                          fill="#ca2946"
                        />
                        <path
                          id="Path_90"
                          data-name="Path 90"
                          d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                          transform="translate(-254.18 -254.18)"
                          fill="#ca2946"
                        />
                        <path
                          id="Path_91"
                          data-name="Path 91"
                          d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                          transform="translate(-349.262 -310.664)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_21"
                          data-name="Ellipse 21"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(16 11)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_22"
                          data-name="Ellipse 22"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(11 16)"
                          fill="#ca2946"
                        />
                        <ellipse
                          id="Ellipse_23"
                          data-name="Ellipse 23"
                          cx="1"
                          cy="1.5"
                          rx="1"
                          ry="1.5"
                          transform="translate(6 11)"
                          fill="#ca2946"
                        />
                        <ellipse
                          id="Ellipse_24"
                          data-name="Ellipse 24"
                          cx="1"
                          cy="1.5"
                          rx="1"
                          ry="1.5"
                          transform="translate(6 16)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_25"
                          data-name="Ellipse 25"
                          cx="1"
                          cy="1"
                          r="1"
                          transform="translate(6 22)"
                          fill="#ca2946"
                        />
                        <ellipse
                          id="Ellipse_26"
                          data-name="Ellipse 26"
                          cx="1.5"
                          cy="1"
                          rx="1.5"
                          ry="1"
                          transform="translate(11 22)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_27"
                          data-name="Ellipse 27"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(11 11)"
                          fill="#ca2946"
                        />
                      </g>
                    </g>
                  </svg>
                }
              />
              <span style={{ fontSize: '15px', marginRight: '28%' }}>
                {moment(date_from).format('YYYY/MM/DD')}
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ fontSize: '13px' }}>إلى</span>
              <DatePicker
                selected={new Date()}
                locale="ar"
                onChange={async (date) => {
                  await setDateTo(moment(date).format('YYYY-MM-DD'));
                  socket.current.emit(
                    `tasks`,
                    {
                      accountId,
                      date_from: moment(date_from).format('YYYY-MM-DD'),
                      date_to: moment(date).format('YYYY-MM-DD'),
                    },
                    (error) => {
                      if (error) {
                        alert(error);
                      }
                    },
                  );
                }}
                customInput={
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g id="Group_28" data-name="Group 28">
                      <g id="Group_27" data-name="Group 27">
                        <ellipse
                          id="Ellipse_20"
                          data-name="Ellipse 20"
                          cx="1"
                          cy="1.5"
                          rx="1"
                          ry="1.5"
                          transform="translate(22 11)"
                          fill="#ca2946"
                        />
                        <path
                          id="Path_89"
                          data-name="Path 89"
                          d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                          fill="#ca2946"
                        />
                        <path
                          id="Path_90"
                          data-name="Path 90"
                          d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                          transform="translate(-254.18 -254.18)"
                          fill="#ca2946"
                        />
                        <path
                          id="Path_91"
                          data-name="Path 91"
                          d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                          transform="translate(-349.262 -310.664)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_21"
                          data-name="Ellipse 21"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(16 11)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_22"
                          data-name="Ellipse 22"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(11 16)"
                          fill="#ca2946"
                        />
                        <ellipse
                          id="Ellipse_23"
                          data-name="Ellipse 23"
                          cx="1"
                          cy="1.5"
                          rx="1"
                          ry="1.5"
                          transform="translate(6 11)"
                          fill="#ca2946"
                        />
                        <ellipse
                          id="Ellipse_24"
                          data-name="Ellipse 24"
                          cx="1"
                          cy="1.5"
                          rx="1"
                          ry="1.5"
                          transform="translate(6 16)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_25"
                          data-name="Ellipse 25"
                          cx="1"
                          cy="1"
                          r="1"
                          transform="translate(6 22)"
                          fill="#ca2946"
                        />
                        <ellipse
                          id="Ellipse_26"
                          data-name="Ellipse 26"
                          cx="1.5"
                          cy="1"
                          rx="1.5"
                          ry="1"
                          transform="translate(11 22)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_27"
                          data-name="Ellipse 27"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(11 11)"
                          fill="#ca2946"
                        />
                      </g>
                    </g>
                  </svg>
                }
              />
              <span style={{ fontSize: '15px', marginRight: '28%' }}>
                {moment(date_to).format('YYYY/MM/DD')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
        <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                key={columnId}
              >
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: 'rgb(244, 245, 247)',
                            padding: 4,
                            width: 300,
                            borderRadius: 10,
                            minHeight: 500,
                          }}
                        >
                          <div
                            style={{
                              height: '40px',
                              marginTop: '2%',
                            }}
                          >
                            <h2 style={{ textAlign: 'center' }}>{column.name}</h2>
                          </div>
                          {column.items.map((item, index) => {
                            let compte = item.login_access_lawyer
                              ? item.login_access_lawyer.lawyer
                              : item.login_access_secretary.secretary;
                            return (
                              <Draggable
                                key={item.dnd_id}
                                draggableId={item.dnd_id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      className="task"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        height: '100%',
                                        userSelect: 'none',
                                        padding: 16,
                                        margin: '0 0 8px 0',
                                        minHeight: '50px',
                                        borderRadius: 10,
                                        /*backgroundColor: snapshot.isDragging
                                          ? '#f1f1f1'
                                          : '#456C86',*/
                                        backgroundColor: '#ffffff',
                                        color: 'white',
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <div style={{ display: 'block' }}>
                                        <div>
                                          <span
                                            style={{ fontSize: '16px', color: 'black' }}
                                          >
                                            {classTask[item.class]}
                                          </span>
                                          {isHasPermission(
                                            account.login_access.permissions,
                                            'حذف-المهام',
                                          ) ? (
                                            <div className="ed-opts option-task">
                                              <a
                                                onClick={() => {
                                                  if (
                                                    isHasPermission(
                                                      account.login_access.permissions,
                                                      'حذف-المهام',
                                                    )
                                                  ) {
                                                    setOpenDeletePopup(true);
                                                    setInitialValues({
                                                      id: item.id,
                                                    });
                                                  }
                                                }}
                                                style={{
                                                  left: '2px',
                                                  top: '-15px',
                                                  cursor: 'pointer',
                                                  maxHeight: '10px',
                                                }}
                                                title=""
                                                className="ed-opts-open"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="20.906"
                                                  height="25.74"
                                                  viewBox="0 0 20.906 25.74"
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <g
                                                    id="delete"
                                                    transform="translate(0 0)"
                                                  >
                                                    <path
                                                      id="Path_82"
                                                      data-name="Path 82"
                                                      d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                                                      transform="translate(-208.991 -145.377)"
                                                      fill="#868686"
                                                    ></path>
                                                    <path
                                                      id="Path_83"
                                                      data-name="Path 83"
                                                      d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                                                      transform="translate(-98.105 -145.377)"
                                                      fill="#868686"
                                                    ></path>
                                                    <path
                                                      id="Path_84"
                                                      data-name="Path 84"
                                                      d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                                                      transform="translate(0.003 0.002)"
                                                      fill="#868686"
                                                    ></path>
                                                    <path
                                                      id="Path_85"
                                                      data-name="Path 85"
                                                      d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                                                      transform="translate(-153.548 -145.377)"
                                                      fill="#868686"
                                                    ></path>
                                                  </g>
                                                </svg>
                                              </a>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                        <div
                                          onClick={() => {
                                            if (
                                              isHasPermission(
                                                account.login_access.permissions,
                                                'تعديل-المهام',
                                              )
                                            ) {
                                              setInitialValues({
                                                accountId,
                                                id: item.id,
                                                reporter_id: item.reporter_id,
                                                title: item.title,
                                                description: item.description,
                                                importance: item.importance,
                                                class: item.class,
                                                type: item.type,
                                              });
                                              setOpenOption(false);
                                              setTask(item);
                                              setOpenCreatePostPopup(true);
                                            }
                                          }}
                                        >
                                          <div className="msng_cards">
                                            <div
                                              style={{
                                                marginRight: '-5%',
                                                background: 'white',
                                              }}
                                              className="msng_card"
                                            >
                                              <div className="msng_card__name">
                                                <div className="msng_card__name-txt">
                                                  <div
                                                    style={{
                                                      fontSize: '12px',
                                                      color: 'black',
                                                      display: 'flex',
                                                    }}
                                                  >
                                                    <BookmarkBorderIcon
                                                      style={{
                                                        marginTop: '5%',
                                                        display: 'flex',
                                                      }}
                                                      color="success"
                                                    />
                                                    مهمة رقم {item.number}
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  margin: '-20px',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    fontSize: '1rem',
                                                    color: 'black',
                                                    width: '1.5rem',
                                                    marginLeft: '5px',
                                                    height: '2rem',
                                                    textAlign: 'center',
                                                  }}
                                                >
                                                  {item.importance === 'high' ? (
                                                    <ArrowUpwardIcon
                                                      style={{ display: 'flex' }}
                                                      color="error"
                                                    />
                                                  ) : (
                                                    <img src={Medium} />
                                                  )}
                                                </div>
                                                <div
                                                  style={{
                                                    fontSize: '1.5rem',
                                                    color: 'black',
                                                    background: '#f4f5f7',
                                                    width: '3rem',
                                                    height: '3rem',
                                                    borderRadius: '50%',
                                                    textAlign: 'center',
                                                  }}
                                                >
                                                  {compte.thumbnail ? (
                                                    <img
                                                      style={{
                                                        width: '70%',
                                                        marginBottom: '20%',
                                                      }}
                                                      src={`${host}${compte.thumbnail}`}
                                                    />
                                                  ) : (
                                                    compte.first_name.charAt(0)
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
      <div
        style={{ width: '50%' }}
        className={openCreatePostPopup ? 'popup active' : 'popup'}
      >
        <div className="popup__title">اضافة مهمة</div>
        <div className="clients_form__sections">
          <div className="clients_form__sections-right">
            <div>
              <label htmlFor="city">يسند إلى</label>
              <select
                className={hasError && !initialValues.reporter_id ? 'input-error' : ''}
                name="reporter_id"
                onChange={(e) =>
                  setInitialValues({
                    ...initialValues,
                    reporter_id: e.target.value,
                  })
                }
                value={initialValues.reporter_id}
              >
                <option value="">المستخدم</option>
                {secretaries && secretaries.length > 0 ? (
                  secretaries.map((secretary) => {
                    return (
                      <option key={secretary.id} value={secretary.login_access.id}>
                        {`${secretary.first_name} ${secretary.last_name}`}
                      </option>
                    );
                  })
                ) : (
                  <option value="">لا يوجد مستخدم</option>
                )}
              </select>
            </div>
            {/*  <div>
              <label htmlFor="phone">العنوان</label>
              <input
                className={hasError && !initialValues.title ? 'input-error' : ''}
                onChange={(e) =>
                  setInitialValues({
                    ...initialValues,
                    title: e.target.value,
                  })
                }
                value={initialValues.title}
                name="title"
                placeholder="العنوان"
                type="text"
              />
            </div>*/}
            <div>
              <label htmlFor="address">الخانة</label>
              <select
                className={hasError && !initialValues.class ? 'input-error' : ''}
                name="class"
                onChange={(e) =>
                  setInitialValues({
                    ...initialValues,
                    class: e.target.value,
                  })
                }
                value={initialValues.class}
              >
                <option value="">الخانة</option>
                <option value="appointment">المواعيد</option>
                <option value="session">الجلسات</option>
                <option value="lawsuit">الدعاوى</option>
                <option value="contract">العقود</option>
                <option value="report">تبادل التقارير</option>
                <option value="appeal">الطعون</option>
                <option value="customer">ملفات الحرفاء</option>
              </select>
            </div>
            <div>
              <label htmlFor="address">الأهمية</label>
              <select
                className={hasError && !initialValues.importance ? 'input-error' : ''}
                name="importance"
                onChange={(e) =>
                  setInitialValues({
                    ...initialValues,
                    importance: e.target.value,
                  })
                }
                value={initialValues.importance}
                style={{ width: '70%', marginRight: '18%' }}
              >
                <option value="">الأهمية</option>
                <option value="high">مرتفعة</option>
                <option value="medium">عادية</option>
              </select>
              {initialValues.importance === 'high' ? (
                <ArrowUpwardIcon color="error" />
              ) : (
                <img style={{ width: '8%' }} src={Medium} />
              )}
            </div>
            {task ? (
              <div>
                <label htmlFor="address">الحالة</label>
                <select
                  className={hasError && !initialValues.type ? 'input-error' : ''}
                  name="type"
                  onChange={(e) =>
                    setInitialValues({
                      ...initialValues,
                      type: e.target.value,
                    })
                  }
                  value={initialValues.type}
                  style={{ width: '70%', marginRight: '20%' }}
                >
                  <option value="">الحالة</option>
                  <option value="to_do">ما يجب انجازه</option>
                  <option value="in_progress">في تقدم</option>
                  <option value="done">منجز</option>
                </select>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="clients_form__sections-left" style={{ display: 'block' }}>
            <div>
              <label htmlFor="address">الوصف</label>
              <textarea
                className={hasError && !initialValues.description ? 'input-error' : ''}
                onChange={(e) =>
                  setInitialValues({
                    ...initialValues,
                    description: e.target.value,
                  })
                }
                style={{ height: '18rem' }}
                value={initialValues.description}
                name="description"
                id="lawsuit_requests"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="popup__btn_grp">
          <button
            onClick={() => {
              setTask(null);
              setOpenCreatePostPopup(!openCreatePostPopup);
              setHasError(false);
            }}
            className="cancel_popup"
          >
            <span>الغاء</span>
            <span>&lt;</span>
          </button>
          <button
            onClick={(e) => {
              if (initialValues.id) updateTask(e);
              else addTask(e);
              // setOpenCreatePostPopup(!openCreatePostPopup);
            }}
            style={{ background: '#63B17D' }}
          >
            <span>تأكيد</span>
            <span className="trashSpan">
              <svg
                className="tickSpan"
                xmlns="http://www.w3.org/2000/svg"
                width="20.125"
                height="17.774"
                viewBox="0 0 29.125 23.774"
              >
                <path
                  id="Path_92"
                  data-name="Path 92"
                  d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                  transform="translate(-1323.842 -156.357)"
                  fill="none"
                  stroke="#f9f9f9"
                  strokeWidth="4"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div
        id="overlay"
        className={openCreatePostPopup || openCreatePostPopup ? 'active' : ''}
      ></div>
      <div className={openDeletePopup ? 'popup active' : 'popup'}>
        <div className="popup__title">أنت بصدد حذف مهمة</div>
        <div className="popup__btn_grp">
          <button onClick={() => setOpenDeletePopup(false)} className="cancel_popup">
            <span>الغاء</span>
            <span>&lt;</span>
          </button>
          <button onClick={() => deleteTask()}>
            <span>تأكيد</span>
            <span className="trashSpan">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.906"
                height="25.74"
                viewBox="0 0 20.906 25.74"
              >
                <g id="delete" transform="translate(0.003 0.002)">
                  <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                    transform="translate(-208.995 -145.379)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                    transform="translate(-98.108 -145.379)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                    transform="translate(0 0)"
                    fill="#fff"
                  ></path>
                  <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                    transform="translate(-153.551 -145.379)"
                    fill="#fff"
                  ></path>
                </g>
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div id="overlay" className={openDeletePopup ? 'active' : ''}></div>
    </div>
  );
}

export default ListTasks;
