import React, { Component } from 'react';
import actions from '../../js/actions';
import actionsSession from './store/actions';
import { connect } from 'react-redux';
import Table from '../../components/Table';
import Filter from './components/filter';
import { Link } from 'react-router-dom';
import { isHasPermission } from '../../utils/helpres';

const mapStateToProps = (state) => ({
  account: state.AppReducer.account,
  sessions: state.AppReducer.sessions,
  addSession: state.SessionReducer.addSession,
  isLoadingSessions: state.AppReducer.isLoadingSessions,
  paramsSessions: state.AppReducer.paramsSessions,
  destroySession: state.SessionReducer.destroySession,
  isLoadingDestroySession: state.SessionReducer.isLoadingDestroySession,
});

const mapDispatchToProps = (dispatch) => ({
  getListSessions: (paramsSessions) =>
    dispatch(actions.getListSessions(paramsSessions, 0)),
  changeSearchedStringSession: async (paramsSessions) => {
    await dispatch(actions.changeSearchedStringSession(paramsSessions));
    dispatch(actions.getListSessions(paramsSessions, 0));
  },
  deleteSession: async (paramsSessions, id) => {
    await dispatch(actionsSession.deleteSession(id));
    dispatch(actions.getListSessions(paramsSessions, 0));
  },
  updateSession: async (paramsSessions, values) => {
    await dispatch(actionsSession.submitSessionForm(values));
    dispatch(actions.getListSessions(paramsSessions, 0));
  },
});

class Session extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBar(1, 'الجلسات');
    document.title = 'الجلسات';
  }
  componentDidMount() {
    this.props.changeSearchedStringSession({
      search: '',
      date:
        this.props.location.state && this.props.location.state.date
          ? this.props.location.state.date
          : '',
      currentPage: 1,
      limit: 5,
      order: 'DESC',
    });
  }
  render() {
    const {
      account,
      sessions,
      destroySession,
      addSession,
      isLoadingSessions,
      paramsSessions,
    } = this.props;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-الجلسات',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-الجلسات',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-الجلسات',
    );
    return (
      <div className="jalaset_main">
        <div className="jalaset_middle">
          <div className="table_controls">
            {isPermissionAdd ? (
              <Link to="/add-session" className="jalaset_add_button">
                <svg
                  height="10"
                  viewBox="0 0 448 448"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                </svg>
                اضافة جلسة
              </Link>
            ) : (
              ''
            )}
            <div className="sort_grp">
              <div className="sort_grp__txt">ترتيب حسب</div>
              <Filter
                changeSearchedStringSession={this.props.changeSearchedStringSession}
                paramsSessions={paramsSessions}
              />
            </div>
          </div>
          <Table
            class1="jalaset__judgements_table"
            class2="jalaset_table"
            colSpan={5}
            dataTable="session"
            messageDelete="أنت بصدد حذف الجلسة"
            withTitle={false}
            action={true}
            isLoading={isLoadingSessions}
            params={paramsSessions}
            changeSearchedString={this.props.changeSearchedStringSession}
            data={sessions}
            //sessions={sessions}
            title="الجلسات"
            isPermissionEdit={isPermissionEdit}
            isPermissionDelete={isPermissionDelete}
            isPermissionAdd={isPermissionAdd}
            editPath="edit-session"
            deletePath={this.props.deleteSession}
            destroy={destroySession}
            updateSession={true}
            addSession={addSession}
            updateSessionFunction={this.props.updateSession}
            destroyYes={true}
            headerItems={[
              'الحريف',
              'الدعوى',
              'تاريخ الجلسة',
              'المحكمة',
              'نوع الجلسة',
              'الإعدادت',
            ]}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Session);
