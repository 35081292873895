import React, { Component } from 'react';
import Form from './containers/form';
import actions from './store/actions';
import actionsApp from '../../js/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

const mapStateToProps = (state) => ({
  customer: state.AppReducer.customer,
  isLoadingCustomer: state.AppReducer.isLoadingCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerById: (id) => dispatch(actions.getCustomerById(id)),
});

class FormCustomer extends Component {
  constructor(props) {
    super(props);

    if (props.match.params && props.match.params.id) {
      props.changeActiveItemSideBar(8, 'تحديث حريف');
      document.title = 'تحديث حريف';
    } else {
      props.changeActiveItemSideBar(8, 'اضافة حريف');
      document.title = 'اضافة حريف';
    }
    props.getCustomerById(props.match.params && props.match.params.id);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    const { customer, isLoadingCustomer } = this.props;
    if (isLoadingCustomer) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }
    return (
      <div style={{ marginBottom: '5%' }} className="clients_newClient show_block">
        <Form goBack={this.goBack} customer={customer} />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormCustomer);
