const initialLawsuitState = {
  addCustomer: null,
  isLoadingAddCustomer: false,
  lawsuit: null,
  isLoadingLawsuit: false,
  isLoadingDeleteCustomer: false,
  destroyCustomer: false,
};

const CustomerReducer = (state = initialLawsuitState, action) => {
  switch (action.type) {
    case 'ACTION_DELETE_CUSTOMER_REQUESTING':
      return {
        ...state,
        isLoadingDeleteCustomer: true,
      };
    case 'ACTION_DELETE_CUSTOMER_SUCCESS':
      return {
        ...state,
        isLoadingDeleteCustomer: false,
        destroyCustomer: true,
      };
    case 'ACTION_DELETE_CUSTOMER_FAILURE':
      return {
        ...state,
        isLoadingDeleteCustomer: false,
        destroyCustomer: null,
      };

    case 'UPDATE_CUSTOMER_REQUESTING':
      return {
        ...state,
        isLoadingAddCustomer: true,
      };
    case 'UPDATE_CUSTOMER_SUCCESS':
      return {
        ...state,
        isLoadingAddCustomer: false,
        addCustomer: action.addCustomer,
      };
    case 'UPDATE_CUSTOMER_FAILURE':
      return {
        ...state,
        isLoadingAddCustomer: false,
        addCustomer: null,
      };

    default:
      return state;
  }
};

export default CustomerReducer;
