import moment from 'moment';
import React from 'react';

const PHONE_FR_FORMAT_REGEXP = new RegExp(/^\+216\d{9}$/, 'i');
const momentLocale = 'YYYY-MM-DD HH:MM:SS';
export const ERROR_JS = 0;
export const ERROR_NETWORK = 600;
export const ERROR_AUTH = 401;
export const ERROR_REQ = 400;
export const ERROR_APP = 700;
export const ERROR_SERVER = 500;
const PHONE_INTL_FORMAT_REGEXP = new RegExp(/^\+\d+$/, 'i');
export const TOAST_CLOSE_DELAY = 8000;

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const lengthPhone = (n) => {
  return n.toString().length === 8;
};

export const firstLetterToUppercase = (value) => {
  return `${value.charAt(0).toUpperCase()}${value.substring(1).toLowerCase()}`;
};
export const firstNumberCin = (n) => {
  if (parseInt(n[0]) === 0 || parseInt(n[0]) === 1) return true;
  return false;
};
export const firstNumberPhone = (n) => {
  if (
    parseInt(n[0]) === 2 ||
    parseInt(n[0]) === 4 ||
    parseInt(n[0]) === 5 ||
    parseInt(n[0]) === 7 ||
    parseInt(n[0]) === 3 ||
    parseInt(n[0]) === 9
  )
    return true;
  return false;
};

export const validate = (value) => {
  const re =
    /^(?:[a-zA-Z0-9\s@,=%$#&_\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){0,30}$/;
  if (re.test(value)) return true;
  else return false;
};

export const validPassword = (value) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value);
};

export const getStatusError = (error) => {
  if (error.response) return error.response.status;
  if (error.message === 'Network Error') return ERROR_NETWORK;
  return ERROR_JS;
};

export const getAppErrorCode = (status) => {
  if (!status) return ERROR_NETWORK;
  else if (status === ERROR_AUTH) return ERROR_AUTH;
  else if (status === ERROR_REQ) return ERROR_REQ;
  else if (status === ERROR_APP) return undefined;
  return ERROR_SERVER;
};

export const isAmount = (string, allowEmptyString = false, allowThreeDecimal = false) => {
  const r = allowThreeDecimal ? /^-?[\d]*[,.]?[\d]{0,3}$/ : /^-?[\d]*[,.]?[\d]{0,2}$/;
  if (allowEmptyString && string === '') return true;
  const regex = new RegExp(r, 'i');
  return !!string.match(regex);
};

export const tryGetAmount = (value, allowThreeDecimal = false) => {
  if (typeof value === 'number') return value;
  if (!value || value.length === 0) return Number.NaN;
  // Remove space and euro characters
  const processed = value.replace(/\s/g, '').replace(/€/g, '');
  if (!isAmount(processed, false, allowThreeDecimal)) return Number.NaN;
  // Parse float with comma authorized in addition to dot
  return parseFloat(processed.replace(/,/g, '.'));
};

export const getAmount = (value, allowThreeDecimal = false) => {
  const parsed = tryGetAmount(value, allowThreeDecimal);
  if (Number.isNaN(parsed)) return 0.0;
  return parsed;
};

export const formatAmount = (
  value,
  ensureFractionDigits = true,
  emptyIfZero = false,
  allowThreeDecimal = false,
) => {
  const amount = getAmount(value, allowThreeDecimal);
  return amount || !emptyIfZero
    ? amount.toLocaleString('fr-FR', {
        minimumFractionDigits: ensureFractionDigits ? 2 : undefined,
        maximumFractionDigits: allowThreeDecimal ? 3 : 2,
      })
    : '';
};

export const isDate = (string) => {
  if (!string || string === '') return false;
  const parsedDate = moment(string, momentLocale);
  const regex = new RegExp(/^[\d]{1,2}\/[\d]{1,2}(\/[\d]{1,4})?$/, 'i');
  return parsedDate.isValid() && !!string.match(regex);
};

export const isFloat = (a) => {
  return a - a === 0 && a.toString(32).indexOf('.') !== -1;
};

export const isAuthorized = (user, roles) => {
  const userRoles = user.Roles;
  if (!userRoles) {
    return false;
  }
  const userRolesCodes = userRoles.map((role) => role.code);
  return roles.some((role) => userRolesCodes.includes(role));
};

export const setFormRoles = (userRoles, roles) => {
  const formRoles = {
    Own: [],
    Other: [],
  };
  roles.forEach((role) => {
    if (userRoles.some((_role) => _role.code === role.code)) {
      formRoles.Own.push(role);
    } else {
      formRoles.Other.push(role);
    }
  });
  return formRoles;
};

export const phoneToPrettyFormat = (number) => {
  if (!number) return '';
  if (!number.match(PHONE_FR_FORMAT_REGEXP)) return number;
  const _num = number.replace(/^\+216/, '0');
  return `${_num.substr(0, 2)} ${_num.substr(2, 2)} ${_num.substr(4, 2)} ${_num.substr(
    6,
    2,
  )} ${_num.substr(8, 2)}`;
};
/**
 * Format french phone number to be international (starts with '+33')
 * Note: already international numbers are returned untouched
 * @param {string} number
 */
export const phoneNumberToIntlFormat = (number) => {
  if (!number) return '';
  //if (number.match(PHONE_INTL_FORMAT_REGEXP)) return number;
  return number.substr(3, 10);
  // .replace(/^0/, '+216');
};

export const diffDate = (dateMessage) => {
  const dateNow = moment(moment().format('DD-MM-YYYY HH:mm:dd'), 'DD-MM-YYYY HH:mm:dd');
  dateMessage = moment(
    moment(dateMessage).format('DD-MM-YYYY HH:mm:dd'),
    'DD-MM-YYYY HH:mm:dd',
  );
  var duration = moment.duration(dateMessage.diff(dateNow));
  let time;
  const days = duration.asDays() * -1;
  const hours = duration.asHours() * -1;
  const minutes = duration.asMinutes() * -1;
  time = moment(dateMessage).format('HH:mm');
  //if (minutes > 5) {
  if (minutes === 0) {
    time = '';
  }
  if (minutes < 59 && minutes > 1) {
    time = minutes + ' دقائق';
  } else if (hours > 24 && (days < 30 || days < 31)) {
    switch (moment(dateMessage).locale('en').format('ddd')) {
      case 'Mon':
        time = 'الاثنين ' + moment(dateMessage).format('HH:mm');
        break;
      case 'Tue':
        time = 'الثلاثاء ' + moment(dateMessage).format('HH:mm');
        break;
      case 'Wed':
        time = 'الأربعاء ' + moment(dateMessage).format('HH:mm');
        break;
      case 'Thu':
        time = 'الخميس ' + moment(dateMessage).format('HH:mm');
        break;
      case 'Fri':
        time = 'الجمعة ' + moment(dateMessage).format('HH:mm');
        break;
      case 'Sat':
        time = 'السبت ' + moment(dateMessage).format('HH:mm');
        break;
      case 'Sun':
        time = 'الأحد ' + moment(dateMessage).format('HH:mm');
        break;
      default:
        time = 'الأحد ';
        break;
    }
  } else if (days > 30 || days > 31) {
    time = `${moment(dateMessage).format('MM/DD')} ${moment(dateMessage).format(
      'HH:mm',
    )}`;
  }
  return time;
  // } else return null
};

export const generateBillNumber = (num, year) => {
  const currentYear = moment().format('YYYY');
  if (year === currentYear) {
    num++;
    return `${num}/${year}`;
  } else return `1/${currentYear}`;
};

export const getPermissionSelected = (group, code) => {
  let permissions = [];
  group.map((item) => {
    item.permissions.map((permission) => {
      if (permission.code.includes(code)) {
        permissions.push(permission.id);
      }
    });
  });
  return permissions;
};

export const getPermissionFormated = (permissions) => {
  let new_permissions = [];
  permissions.map((permission) => {
    new_permissions.push(permission.id);
  });
  return new_permissions;
};

export const isHasPermission = (permissions, perm) => {
  let isPermission = false;
  permissions.map((permission) => {
    if (permission.code === perm) isPermission = true;
  });
  return isPermission;
};

export const getPricePack = (type, mode) => {
  let price;
  ///'monthly', 'semi-annual', 'annual'
  if (type === 'beginner') {
    if (mode === 'monthly') price = parseFloat(60 + 60 * 0.1).toFixed(2);
    if (mode === 'annual') price = parseFloat(210 + 210 * 0.1).toFixed(2);
  } else if (type === 'office') {
    if (mode === 'monthly') price = parseFloat(90 + 90 * 0.1).toFixed(2);
    if (mode === 'annual') price = parseFloat(290 + 290 * 0.1).toFixed(2);
  } else if (type === 'our_office') {
    if (mode === 'monthly') price = parseFloat(195 + 195 * 0.1).toFixed(2);
    if (mode === 'annual') price = parseFloat(690 + 690 * 0.1).toFixed(2);
  } else {
    if (mode === 'monthly') price = parseFloat(320 + 320 * 0.1).toFixed(2);
    if (mode === 'annual') price = parseFloat(1050 + 1050 * 0.1).toFixed(2);
  }
  return price;
};

export const newDataLawyers = (lawyers) => {
  let data = [];
  if (lawyers.length > 0)
    lawyers.map((item) => {
      data.push({ id: item.id, title: `${item.first_name} ${item.last_name}` });
    });
  return data;
};
