import React, { Component } from 'react';
import actions from '../../js/actions';
import actionsBill from './store/actions';
import { connect } from 'react-redux';
import SearchIcon from '../../assets/img/search-interface-symbol_sm.svg';
import { Link } from 'react-router-dom';
import Item from './components/item';
import { Skeleton } from '@material-ui/lab';
import { DaysArabic } from '../../utils/constants';
import moment from 'moment';
import EmptyData from '../../assets/img/undraw_design_team_af2y.svg';
import { isHasPermission } from '../../utils/helpres';
import Tooltip from '@mui/material/Tooltip';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const mapStateToProps = (state) => ({
  customerSelected: state.AppReducer.customerSelected,
  bills: state.AppReducer.bills,
  isLoadingCustomers: state.AppReducer.isLoadingCustomers,
  bill: state.BillReducer.bill,
  destroyBill: state.BillReducer.destroyBill,
  isLoadingBill: state.BillReducer.isLoadingBill,
  paramsBill: state.AppReducer.paramsBill,
  account: state.AppReducer.account,
});

const mapDispatchToProps = (dispatch) => ({
  getListBills: (paramsBill) => dispatch(actions.getListBills(paramsBill, 0)),
  getBillById: (id) => dispatch(actionsBill.getBillById(id)),
  changeSearchedStringBill: async (paramsBill) => {
    await dispatch(actions.changeSearchedStringBill(paramsBill));
    dispatch(actions.getListBills(paramsBill, 0));
  },
  deleteBill: async (paramsBill, id) => {
    await dispatch(actionsBill.deleteBill(id));
    dispatch(actions.getListBills(paramsBill, 0));
  },
});

class Bills extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBar(7, 'المحاسبة');
    document.title = 'المحاسبة';
    this.state = {
      bill_selected: null,
      openDeletePopup: false,
      selected_id: false,
    };
    this.handleChangedBillSelected = this.handleChangedBillSelected.bind(this);
    this.handleActivePopupDeleteItem = this.handleActivePopupDeleteItem.bind(this);
  }
  componentDidMount() {
    this.props.changeSearchedStringBill({
      search: '',
      date: '',
      currentPage: 1,
      limit: 5,
    });
  }
  handleChangedBillSelected(id) {
    this.setState({
      bill_selected: id,
    });
    this.props.getBillById(id);
  }
  handleActivePopupDeleteItem(id) {
    this.setState({
      selected_id: id,
      openDeletePopup: !this.state.openDeletePopup,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { destroyBill } = this.props;

    if (destroyBill !== prevProps.destroyBill && destroyBill) {
      this.handleActivePopupDeleteItem();
    }
  }
  render() {
    const { bill, isLoadingBill, account, bills, paramsBill } = this.props;
    const { bill_selected, openDeletePopup, selected_id } = this.state;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-المحاسبة',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-المحاسبة',
    );
    let isShowPermission = isHasPermission(
      account.login_access.permissions,
      'إضافة-المحاسبة',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-المحاسبة',
    );
    return (
      <div className="clients_middle">
        {isPermissionAdd ? (
          <div>
            <Link to="/add-bill" className="clients_add_button clients_add_client_btn">
              <svg
                height="10"
                viewBox="0 0 448 448"
                width="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
              </svg>
              اضافة فاتورة
            </Link>
          </div>
        ) : (
          ''
        )}

        <div className="clients_middle__wrapper">
          {' '}
          <div className="clients_col-right">
            <div className="clients_right_search">
              <img src={SearchIcon} alt="search icon" />
              <input
                onChange={(e) =>
                  this.props.changeSearchedStringBill({
                    ...paramsBill,
                    search: e.target.value,
                  })
                }
                value={paramsBill.search}
                type="text"
              />
            </div>
            {bills && bills.data && bills.data.length > 0
              ? bills.data.map((bill) => {
                  return (
                    <Item
                      handleChangedBillSelected={this.handleChangedBillSelected}
                      getBillById={this.props.getBillById}
                      bill_selected={bill_selected || bills.data[0].id}
                      bill={bill}
                      key={bill.id}
                    />
                  );
                })
              : ''}
          </div>
          {bills && bills.data && bills.data.length > 0 ? (
            <>
              <div className="clients_col-left">
                <div className="clients_details_section">
                  <div
                    style={{
                      height: '3rem',
                      fontWeight: 'bold',
                    }}
                    className="clients_details_section__head"
                  >
                    بيانات الفاتورة
                    {bill && bill.id ? (
                      <div style={{ display: 'flex' }}>
                        {isPermissionEdit ? (
                          <Tooltip title="تحديث">
                            <Link to={`/edit-bill/${bill.id}`}>
                              <svg
                                id="Group_23"
                                data-name="Group 23"
                                xmlns="http://www.w3.org/2000/svg"
                                width="23.304"
                                height="24.288"
                                viewBox="0 0 23.304 24.288"
                                style={{ cursor: 'pointer' }}
                              >
                                <g id="Group_22" data-name="Group 22">
                                  <path
                                    id="Path_86"
                                    data-name="Path 86"
                                    d="M26.636,19.431h.131L33.1,18.053a.623.623,0,0,0,.328-.164L44.163,7.156A4.136,4.136,0,0,0,45.377,4.2a4.267,4.267,0,0,0-1.214-2.987A4.135,4.135,0,0,0,41.209,0a4.037,4.037,0,0,0-2.954,1.247l-10.7,10.733a.591.591,0,0,0-.2.3l-1.378,6.335a.722.722,0,0,0,.2.624A.656.656,0,0,0,26.636,19.431ZM41.209,1.314a2.855,2.855,0,0,1,2.856,2.855q0,.016,0,.033a2.757,2.757,0,0,1-.821,2l-4.037-4.07A2.823,2.823,0,0,1,41.209,1.314ZM38.287,3.086l4.037,4.037L32.97,16.444l-4.037-4ZM28.408,13.753,31.657,17l-4.168.919Z"
                                    transform="translate(-24.83 0)"
                                    fill="#ca2946"
                                  ></path>
                                  <path
                                    id="Path_87"
                                    data-name="Path 87"
                                    d="M30.485,365.717H8.494a.656.656,0,0,0,0,1.313H30.485a.656.656,0,0,0,0-1.313Z"
                                    transform="translate(-7.838 -342.742)"
                                    fill="#ca2946"
                                  ></path>
                                </g>
                              </svg>
                            </Link>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                        {isShowPermission ? (
                          <Tooltip title="تحميل PDF">
                            <Link
                              to={{
                                pathname: '/generate-pdf-bill',
                                state: {
                                  bill: bill,
                                  account: account,
                                },
                              }}
                            >
                              <div title="إظهار البيانات">
                                <ArrowDownwardIcon
                                  className="lawsuit_center_svg"
                                  style={{
                                    background: 'rgb(202, 41, 70)',
                                    color: 'white',
                                    borderRadius: '3px',
                                    width: '25px',
                                    height: '25px',
                                  }}
                                />
                              </div>
                            </Link>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                        {isPermissionDelete ? (
                          <Tooltip title="حذف">
                            <svg
                              onClick={() => this.handleActivePopupDeleteItem(bill.id)}
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.906"
                              height="25.74"
                              viewBox="0 0 20.906 25.74"
                              style={{ cursor: 'pointer' }}
                            >
                              <g id="delete" transform="translate(0 0)">
                                <path
                                  id="Path_82"
                                  data-name="Path 82"
                                  d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                                  transform="translate(-208.991 -145.377)"
                                  fill="#ca2946"
                                ></path>
                                <path
                                  id="Path_83"
                                  data-name="Path 83"
                                  d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                                  transform="translate(-98.105 -145.377)"
                                  fill="#ca2946"
                                ></path>
                                <path
                                  id="Path_84"
                                  data-name="Path 84"
                                  d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                                  transform="translate(0.003 0.002)"
                                  fill="#ca2946"
                                ></path>
                                <path
                                  id="Path_85"
                                  data-name="Path 85"
                                  d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                                  transform="translate(-153.548 -145.377)"
                                  fill="#ca2946"
                                ></path>
                              </g>
                            </svg>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {bill && bill.id ? (
                    isLoadingBill ? (
                      <div className="clients_details_section__body">
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">
                            <Skeleton
                              style={{
                                padding: '10px',
                              }}
                              variant="rect"
                              width={50}
                              height={20}
                            />
                          </div>
                          <div className="clients_data_name">
                            <Skeleton
                              style={{
                                padding: '10px',
                              }}
                              variant="rect"
                              width={150}
                              height={20}
                            />
                          </div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">
                            <Skeleton
                              style={{
                                padding: '10px',
                              }}
                              variant="rect"
                              width={50}
                              height={20}
                            />
                          </div>
                          <div className="clients_data_name">
                            <Skeleton
                              style={{
                                padding: '10px',
                              }}
                              variant="rect"
                              width={150}
                              height={20}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="clients_details_section__body">
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">العدد</div>
                          <div className="clients_data_name">{bill.number}</div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">الحريف</div>
                          <div className="clients_data_addr">{bill.customer.name}</div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">التاريخ</div>
                          <div className="clients_data_addr">
                            {' '}
                            {DaysArabic[moment(bill.date).format('dddd')]}{' '}
                            {moment(bill.date).format('DD/MM/YYYY')}{' '}
                            <span style={{ color: '#ca2946' }}>
                              {moment(bill.date).format('HH:mm')}h
                            </span>
                          </div>
                        </div>
                        {bill.lawsuit ? (
                          <>
                            <div className="clients_details_section__field">
                              <div className="clients_field_title">نوع الدعوى</div>
                              <div className="clients_data_phone">
                                {bill.lawsuit.suit_type.name}
                              </div>
                            </div>
                            <div className="clients_details_section__field">
                              <div className="clients_field_title">الأتعاب</div>
                              <div className="clients_data_addr">{bill.fees}</div>
                            </div>
                            <div className="clients_details_section__field">
                              <div className="clients_field_title">تسبقة</div>
                              <div className="clients_data_addr">{bill.advance_fees}</div>
                            </div>
                            <div className="clients_details_section__field">
                              <div className="clients_field_title">باقي الأتعاب</div>
                              <div className="clients_data_city">
                                {bill.remaining_fees}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="clients_details_section__field">
                              <div className="clients_field_title">نوع العقد</div>
                              <div className="clients_data_phone">
                                {bill.contract ? bill.contract.type_contract.name : ''}
                              </div>
                            </div>
                            {/*<div className="clients_details_section__field">
                              <div className="clients_field_title">الحريف</div>
                              <div className="clients_data_addr">
                                {bill.customer.name}
                              </div>
                            </div>*/}
                            <div className="clients_details_section__field">
                              <div className="clients_field_title">الأتعاب</div>
                              <div className="clients_data_addr">{bill.fees}</div>
                            </div>
                          </>
                        )}
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{ gap: '10%', marginTop: '8%', display: 'grid' }}
                className="clients_col-left"
              >
                <img style={{ textAlign: 'center' }} src={EmptyData} />
                <span style={{ marginRight: '12%', fontSize: '15px' }}>
                  لا يوجد سجل متاح
                </span>
              </div>
            </>
          )}
        </div>
        <div className={openDeletePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">أنت بصدد حذف الفاتورة</div>
          <div className="popup__btn_grp">
            <button
              onClick={() => this.handleActivePopupDeleteItem()}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              onClick={() => {
                this.props.deleteBill(paramsBill, selected_id);
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.906"
                  height="25.74"
                  viewBox="0 0 20.906 25.74"
                >
                  <g id="delete" transform="translate(0.003 0.002)">
                    <path
                      id="Path_82"
                      data-name="Path 82"
                      d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                      transform="translate(-208.995 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_83"
                      data-name="Path 83"
                      d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                      transform="translate(-98.108 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                    <path
                      id="Path_85"
                      data-name="Path 85"
                      d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                      transform="translate(-153.551 -145.379)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div id="overlay" className={openDeletePopup ? 'active' : ''}></div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Bills);
