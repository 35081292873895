import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ar from 'date-fns/locale/ar-DZ';
import { connect } from 'react-redux';
import actions from '../store/actions';
import InputField from '../../../components/inputField';
import { generateBillNumber, isNumeric } from '../../../utils/helpres';
import { DaysArabic } from '../../../utils/constants';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

registerLocale('ar', ar);

const mapStateToProps = (state, ownProps) => {
  const { bill, lastBill } = ownProps;
  let id;
  let date;
  let remaining_fees;
  let advance_fees;
  let fees;
  let customer_name;
  let customer_id;
  let type;
  let type_name;
  if (bill) {
    fees = bill && bill.fees ? bill.fees : 0;
    advance_fees = bill && bill.advance_fees ? bill.advance_fees : 0;
    remaining_fees = bill && bill.remaining_fees ? bill.remaining_fees : 0;
    date = bill && bill.date ? bill.date : 0;
    id = bill && bill.id ? bill.id : null;
    customer_name = bill && bill.customer ? bill.customer.name : '';
    customer_id = bill && bill.customer ? bill.customer.id : '';
    type = bill && bill.lawsuit ? 'lawsuit' : 'contract';
    type_name =
      bill && bill.lawsuit && bill.lawsuit.suit_type
        ? bill.lawsuit.suit_type.name
        : bill.contract
        ? bill.contract.type_contract.name
        : '';
  }
  return {
    addBill: state.BillReducer.addBill,
    isLoadingAddBill: state.BillReducer.isLoadingAddBill,
    account: state.AppReducer.account,
    initialValues: {
      type_name: type_name || null,
      type: type || null,
      id: id || null,
      fees: fees || 0,
      advance_fees: advance_fees || 0,
      remaining_fees: remaining_fees || 0,
      date: date || new Date(),
      number: bill
        ? bill.number
        : lastBill && lastBill.length > 0
        ? `${generateBillNumber(
            parseInt(lastBill[0].number.split('/')[0]),
            moment().format('YYYY'),
          )}`
        : `${generateBillNumber(0, moment().format('YYYY'))}`,
      customer_name: customer_name || '',
      customer_id: customer_id || '',
    },
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitBillForm: (values) => dispatch(actions.submitBillForm(values)),
});

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      initialValues: {
        ...props.initialValues,
      },
    };
    this.submitForm = this.submitForm.bind(this);
  }
  submitForm(initialState) {
    if (
      !initialState.fees ||
      !initialState.advance_fees ||
      !initialState.remaining_fees ||
      (initialState.fees && !isNumeric(initialState.fees)) ||
      (initialState.remaining_fees && !isNumeric(initialState.remaining_fees)) ||
      (initialState.advance_fees && !isNumeric(initialState.advance_fees))
    ) {
      this.setState({
        ...initialState,
        hasError: true,
      });
    } else {
      this.props.submitBillForm(initialState);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addBill } = this.props;
    if (addBill !== prevProps.addBill && addBill) {
      this.props.goBack();
    }
  }
  render() {
    const { initialValues, hasError } = this.state;
    const { customersList } = this.props;
    return (
      <form action="">
        <div style={{ width: '100%' }} className="clients_form__sections">
          <div className="clients_form__sections-right">
            <div>
              <label htmlFor="name">فاتورة عدد</label>
              <div style={{ width: '54%' }}>{initialValues.number}</div>
            </div>
            {initialValues.type ? (
              <div>
                <label htmlFor="name">
                  {initialValues.type === 'lawsuit' ? 'نوع الدعوى' : 'نوع العقد'}
                </label>
                <div style={{ width: '54%' }}>{initialValues.type_name}</div>
              </div>
            ) : (
              ''
            )}
            <div>
              <label style={{ marginLeft: '20%' }} htmlFor="lawsuit_type">
                الحريف
              </label>
              <Autocomplete
                noOptionsText={'قائمة الحرفاء فارغة'}
                debug
                id="debug"
                options={customersList}
                getOptionLabel={(option) => option.title}
                style={{ marginBottom: '4%' }}
                onChange={(event, option) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      customer_id: option != null ? option.id : '',
                    },
                  });
                }}
                defaultValue={{
                  title: initialValues.customer_name,
                }}
                renderInput={(params) => (
                  <TextField
                    error={!initialValues.customer_id && hasError}
                    name="customer_id"
                    style={{ border: 0 }}
                    {...params}
                    //label="المدعي"
                    //variant="outlined"
                  />
                )}
              />
            </div>
            <div>
              <label htmlFor="name">الأتعاب</label>
              <InputField
                //onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.fees}
                name="fees"
                placeholder="الأتعاب"
                type="text"
                hasError={hasError}
                validInput="integer"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      remaining_fees: initialValues.advance_fees
                        ? event.target.value - initialValues.advance_fees
                        : '',
                      fees: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div>
              <label htmlFor="city">تسبقة</label>
              <InputField
                //onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.advance_fees}
                name="advance_fees"
                placeholder="تسبقة"
                type="text"
                hasError={hasError}
                validInput="integer"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      remaining_fees: initialValues.fees
                        ? initialValues.fees - event.target.value
                        : '',
                      advance_fees: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div>
              <label htmlFor="phone">باقي الأتعاب</label>
              <InputField
                disabled={true}
                value={initialValues.remaining_fees}
                name="remaining_fees"
                placeholder="باقي الأتعاب"
                type="text"
                validInput="text"
              />
            </div>
          </div>
          <div className="rv_form__sections-left">
            <div
              style={{
                alignItems: 'center',
              }}
              className="jalaset_form__sections-left"
            >
              <div
                style={{
                  width: '9rem',
                  marginLeft: 0,
                }}
                className="jalaset_form__label"
                htmlFor="date_selected"
              >
                التاريخ
              </div>
              <div className="jalaset_form__cal">
                <DatePicker
                  selected={new Date(initialValues.date)}
                  locale="ar"
                  onChange={(date) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        date: date,
                      },
                    });
                  }}
                  customInput={
                    <svg
                      style={{
                        cursor: 'pointer',
                      }}
                      id="calendar"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <g id="Group_28" data-name="Group 28">
                        <g id="Group_27" data-name="Group 27">
                          <ellipse
                            id="Ellipse_20"
                            data-name="Ellipse 20"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(22 11)"
                            fill="#ca2946"
                          />
                          <path
                            id="Path_89"
                            data-name="Path 89"
                            d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                            fill="#ca2946"
                          />
                          <path
                            id="Path_90"
                            data-name="Path 90"
                            d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                            transform="translate(-254.18 -254.18)"
                            fill="#ca2946"
                          />
                          <path
                            id="Path_91"
                            data-name="Path 91"
                            d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                            transform="translate(-349.262 -310.664)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_21"
                            data-name="Ellipse 21"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(16 11)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_22"
                            data-name="Ellipse 22"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(11 16)"
                            fill="#ca2946"
                          />
                          <ellipse
                            id="Ellipse_23"
                            data-name="Ellipse 23"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(6 11)"
                            fill="#ca2946"
                          />
                          <ellipse
                            id="Ellipse_24"
                            data-name="Ellipse 24"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(6 16)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_25"
                            data-name="Ellipse 25"
                            cx="1"
                            cy="1"
                            r="1"
                            transform="translate(6 22)"
                            fill="#ca2946"
                          />
                          <ellipse
                            id="Ellipse_26"
                            data-name="Ellipse 26"
                            cx="1.5"
                            cy="1"
                            rx="1.5"
                            ry="1"
                            transform="translate(11 22)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_27"
                            data-name="Ellipse 27"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(11 11)"
                            fill="#ca2946"
                          />
                        </g>
                      </g>
                    </svg>
                  }
                />
              </div>

              <div className="jalaset_form__date_selected">
                <div className="jalaset_form__date_selected__day">
                  {DaysArabic[moment(initialValues.date).format('dddd')]}
                </div>
                <div className="jalaset_form__date_selected__day">
                  {moment(initialValues.date).format('DD/MM/YYYY')}
                </div>
              </div>
            </div>
            {/*{hasErrorDate ||
              (initialValues.date &&
                initialValues.date &&
                moment().isBefore(moment(initialValues.date))) ? (
                <div className="rv_form__sections-left_field">
                  <div>
                    <svg
                      className="rv_danger_icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.077"
                      height="24.294"
                      viewBox="0 0 27.077 24.294"
                    >
                      <g id="alert" transform="translate(0 -26.315)">
                        <g
                          id="Group_39"
                          data-name="Group 39"
                          transform="translate(0 26.315)"
                        >
                          <g
                            id="Group_38"
                            data-name="Group 38"
                            transform="translate(0 0)"
                          >
                            <path
                              id="Path_105"
                              data-name="Path 105"
                              d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                              transform="translate(0 -26.315)"
                              fill="#ff8000"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_41"
                          data-name="Group 41"
                          transform="translate(12.745 34.232)"
                        >
                          <g
                            id="Group_40"
                            data-name="Group 40"
                            transform="translate(0 0)"
                          >
                            <rect
                              id="Rectangle_145"
                              data-name="Rectangle 145"
                              width="1.586"
                              height="7.932"
                              fill="#ff8000"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_43"
                          data-name="Group 43"
                          transform="translate(12.481 43.75)"
                        >
                          <g id="Group_42" data-name="Group 42">
                            <path
                              id="Path_106"
                              data-name="Path 106"
                              d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                              transform="translate(-236.002 -355.99)"
                              fill="#ff8000"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="rv_err_msg">الرجاء تحديد تاريخ في المستقبل</div>
                </div>
              ) : (
                ''
              )}*/}
          </div>
        </div>

        <div className="clients_form__btn_grp">
          <button
            type="button"
            onClick={() => this.props.goBack()}
            className="clients_cancel_session"
          >
            <svg
              className="clients_ltSpan"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xmlnsSvgjs="http://svgjs.com/svgjs"
              version="1.1"
              width="12"
              height="12"
              x="0"
              y="0"
              viewBox="0 0 492.004 492.004"
              style={{ enableBackground: 'new 0 0 512 512' }}
              xmlSpace="preserve"
            >
              <g>
                <g xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                      fill="#2d2d2d"
                      data-original="#000000"
                      className=""
                    />
                  </g>
                </g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
              </g>
            </svg>
            الغاء
          </button>
          <button type="button" onClick={() => this.submitForm(initialValues)}>
            <svg
              className="tickSpan"
              xmlns="http://www.w3.org/2000/svg"
              width="20.125"
              height="17.774"
              viewBox="0 0 29.125 23.774"
            >
              <path
                id="Path_92"
                data-name="Path 92"
                d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                transform="translate(-1323.842 -156.357)"
                fill="none"
                stroke="#f9f9f9"
                strokeWidth="4"
              />
            </svg>
            تسجيل
          </button>
        </div>
      </form>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);
