import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { reducer as formReducer } from 'redux-form';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/*Reducers*/
import AppReducer from './js/reducers';
import RegisterReducer from './pages/auth/register/store/reducers';
import SessionReducer from './pages/session/store/reducers';
import LawsuitReducer from './pages/lawsuit/store/reducers';
import CustomerReducer from './pages/customer/store/reducers';
import ReportsReducer from './pages/reports/store/reducers';
import AgendaReducer from './pages/appointment/store/reducers';
import SettingsReducer from './pages/settings/store/reducers';
import AppealReducer from './pages/appeal/store/reducers';
import LegalJournalReducer from './pages/legal-journal/store/reducers';
import JurisprudenceReducer from './pages/jurisprudence/store/reducers';
import MessengerReducer from './pages/messenger/store/reducers';
import ContractReducer from './pages/contract/store/reducers';
import BillReducer from './pages/bill/store/reducers';
import moment from 'moment';

moment.updateLocale('arab', {
  months: [
    'جانفي',
    'فيفري',
    'مارس',
    'أفريل',
    'ماي',
    'جوان',
    'جويلية',
    'أوت',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
  ],
  weekdays: ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
});

const history = createBrowserHistory();
const middlewares = [thunk, routerMiddleware(history)];

const store = createStore(
  combineReducers({
    AppReducer,
    RegisterReducer,
    SettingsReducer,
    AgendaReducer,
    SessionReducer,
    LawsuitReducer,
    CustomerReducer,
    ReportsReducer,
    AppealReducer,
    LegalJournalReducer,
    JurisprudenceReducer,
    MessengerReducer,
    BillReducer,
    ContractReducer,
    router: routerReducer,
    form: formReducer,
  }),
  composeWithDevTools(applyMiddleware(...middlewares)),
);
ReactDOM.render(
  <Suspense fallback="...">
    <Provider store={store}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
